@charset "UTF-8";
:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --primary-color: #2196f3;
  --primary-color-text: #ffffff;
  --font-family: Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial,
    Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

/* Add your customizations of the theme variables here */
* {
  box-sizing: border-box;
}

.p-component {
  font-family:
    'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 1rem;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.6;
}

.p-error {
  color: #f44336;
}

.p-text-secondary {
  color: #6c757d;
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 1rem;
  font-family:
    'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  border-radius: 4px;
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-autocomplete .p-autocomplete-loader {
  right: 0.5rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 2.857rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.25rem 0.5rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #2196f3;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.25rem 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family:
    'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 1rem;
  color: #495057;
  padding: 0;
  margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #e3f2fd;
  color: #495057;
  border-radius: 4px;
}
.p-autocomplete
  .p-autocomplete-multiple-container
  .p-autocomplete-token
  .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}
.p-autocomplete.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}

.p-autocomplete-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.5rem 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #495057;
  background: #e9ecef;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-calendar.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}

.p-calendar.p-warning.p-component > .p-inputtext {
  border-color: #fbc02d;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: #495057;
  background: #e3f2fd;
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-datepicker table td.p-datepicker-today > span {
  background: #ced4da;
  color: #495057;
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #495057;
  background: #e3f2fd;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #495057;
  background: #e3f2fd;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid #dee2e6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-datepicker-mask.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 4px;
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: #2196f3;
}
.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3;
}
.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 0.5rem 0.5rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: #6c757d;
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-cascadeselect.p-invalid.p-component {
  border-color: #f44336;
}

.p-cascadeselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.5rem 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 0.5rem 1rem;
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item
  .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}

.p-input-filled .p-cascadeselect {
  background: #f8f9fa;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 4px;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #2196f3;
  background: #2196f3;
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #0b7ad1;
  background: #0b7ad1;
  color: #ffffff;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #2196f3;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #0b7ad1;
  background: #0b7ad1;
  color: #ffffff;
}
.p-checkbox.p-invalid > .p-checkbox-box {
  border-color: #f44336;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f8f9fa;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #2196f3;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #0b7ad1;
}

.p-chips .p-chips-multiple-container {
  padding: 0.25rem 0.5rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #2196f3;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #e3f2fd;
  color: #495057;
  border-radius: 4px;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.25rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family:
    'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 1rem;
  color: #495057;
  padding: 0;
  margin: 0;
}
.p-chips.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}

.p-colorpicker-preview {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border-color: #191919;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 4px;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #2196f3;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.5rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #6c757d;
  right: 2.357rem;
}
.p-dropdown.p-invalid.p-component {
  border-color: #f44336;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.5rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.5rem 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
  background: transparent;
}

.p-input-filled .p-dropdown {
  background: #f8f9fa;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-editor-container .p-editor-toolbar {
  background: #f8f9fa;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 0.5rem 0;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item {
  color: #495057;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item:hover {
  color: #495057;
  background: #e9ecef;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded:not(.ql-icon-picker)
  .ql-picker-item {
  padding: 0.5rem 1rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #2196f3;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #2196f3;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #2196f3;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #2196f3;
}

.p-inputgroup-addon {
  background: #e9ecef;
  color: #6c757d;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 0.5rem 0.5rem;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: 1px solid #ced4da;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}

.p-inputnumber.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #b6bfc8;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #2196f3;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #0d89ec;
}
.p-inputswitch.p-invalid {
  border-color: #f44336;
}

.p-inputtext {
  font-family:
    'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ced4da;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 4px;
}
.p-inputtext:enabled:hover {
  border-color: #2196f3;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3;
}
.p-inputtext.p-invalid.p-component {
  border-color: #f44336;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.4375rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.625rem;
}

.p-float-label > label {
  left: 0.5rem;
  color: #6c757d;
  transition-duration: 0.2s;
}

.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
  left: 0.5rem;
  color: #6c757d;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2rem;
}

.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
  right: 0.5rem;
  color: #6c757d;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2rem;
}

::-webkit-input-placeholder {
  color: #6c757d;
}

:-moz-placeholder {
  color: #6c757d;
}

::-moz-placeholder {
  color: #6c757d;
}

:-ms-input-placeholder {
  color: #6c757d;
}

.p-input-filled .p-inputtext {
  background-color: #f8f9fa;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: #ffffff;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.4375rem 0.4375rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.625rem 0.625rem;
}

.p-listbox {
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.p-listbox .p-listbox-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.5rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}
.p-listbox .p-listbox-list {
  padding: 0.5rem 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
  background: transparent;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}
.p-listbox.p-invalid {
  border-color: #f44336;
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 4px;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: #2196f3;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3;
}
.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.5rem;
}
.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.5rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #e3f2fd;
  color: #495057;
  border-radius: 4px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-multiselect .p-multiselect-clear-icon {
  color: #6c757d;
  right: 2.357rem;
}
.p-multiselect.p-invalid.p-component {
  border-color: #f44336;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.5rem;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.5rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.5rem;
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-multiselect-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
  background: transparent;
}

.p-input-filled .p-multiselect {
  background: #f8f9fa;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-password.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}

.p-password-panel {
  padding: 1rem;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: #dee2e6;
}
.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #d32f2f;
}
.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #fbc02d;
}
.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #689f38;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #2196f3;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  background-color: #ffffff;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #2196f3;
  background: #2196f3;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #0b7ad1;
  background: #0b7ad1;
  color: #ffffff;
}
.p-radiobutton.p-invalid > .p-radiobutton-box {
  border-color: #f44336;
}
.p-radiobutton:focus {
  outline: 0 none;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #f8f9fa;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #2196f3;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #0b7ad1;
}

.p-rating .p-rating-icon {
  color: #495057;
  margin-left: 0.5rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: #e74c3c;
}
.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.pi-star {
  color: #2196f3;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #2196f3;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #c0392b;
}

.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #6c757d;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #6c757d;
}
.p-selectbutton .p-button.p-highlight {
  background: #2196f3;
  border-color: #2196f3;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #0d89ec;
  border-color: #0d89ec;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton.p-invalid > .p-button {
  border-color: #f44336;
}

.p-slider {
  background: #dee2e6;
  border: 0 none;
  border-radius: 4px;
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #ffffff;
  border: 2px solid #2196f3;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-slider .p-slider-range {
  background: #2196f3;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #2196f3;
  border-color: #2196f3;
}

.p-togglebutton.p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #6c757d;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #6c757d;
}
.p-togglebutton.p-button.p-highlight {
  background: #2196f3;
  border-color: #2196f3;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover {
  background: #0d89ec;
  border-color: #0d89ec;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-invalid > .p-button {
  border-color: #f44336;
}

.p-button {
  color: #ffffff;
  background: #2196f3;
  border: 1px solid #2196f3;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 4px;
}
.p-button:enabled:hover {
  background: #0d89ec;
  color: #ffffff;
  border-color: #0d89ec;
}
.p-button:enabled:active {
  background: #0b7ad1;
  color: #ffffff;
  border-color: #0b7ad1;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #2196f3;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(33, 150, 243, 0.04);
  color: #2196f3;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(33, 150, 243, 0.16);
  color: #2196f3;
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button.p-button-text {
  background-color: transparent;
  color: #2196f3;
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: rgba(33, 150, 243, 0.04);
  color: #2196f3;
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: rgba(33, 150, 243, 0.16);
  color: #2196f3;
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #2196f3;
  background-color: #ffffff;
}
.p-button.p-button-raised {
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0.5rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.875rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.625rem 1.25rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 2.357rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: #ffffff;
  background: #607d8b;
  border: 1px solid #607d8b;
}
.p-button.p-button-secondary:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #56717d;
  color: #ffffff;
  border-color: #56717d;
}
.p-button.p-button-secondary:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #beccd2;
}
.p-button.p-button-secondary:enabled:active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #4d646f;
  color: #ffffff;
  border-color: #4d646f;
}
.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #607d8b;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(96, 125, 139, 0.04);
  color: #607d8b;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(96, 125, 139, 0.16);
  color: #607d8b;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #607d8b;
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(96, 125, 139, 0.04);
  border-color: transparent;
  color: #607d8b;
}
.p-button.p-button-secondary.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(96, 125, 139, 0.16);
  border-color: transparent;
  color: #607d8b;
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #0288d1;
  border: 1px solid #0288d1;
}
.p-button.p-button-info:enabled:hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #027abc;
  color: #ffffff;
  border-color: #027abc;
}
.p-button.p-button-info:enabled:focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #89d4fe;
}
.p-button.p-button-info:enabled:active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #026da7;
  color: #ffffff;
  border-color: #026da7;
}
.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #0288d1;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(2, 136, 209, 0.04);
  color: #0288d1;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(2, 136, 209, 0.16);
  color: #0288d1;
  border: 1px solid;
}
.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #0288d1;
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(2, 136, 209, 0.04);
  border-color: transparent;
  color: #0288d1;
}
.p-button.p-button-info.p-button-text:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(2, 136, 209, 0.16);
  border-color: transparent;
  color: #0288d1;
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #689f38;
  border: 1px solid #689f38;
}
.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #5e8f32;
  color: #ffffff;
  border-color: #5e8f32;
}
.p-button.p-button-success:enabled:focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #c2e0a8;
}
.p-button.p-button-success:enabled:active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #537f2d;
  color: #ffffff;
  border-color: #537f2d;
}
.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #689f38;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(104, 159, 56, 0.04);
  color: #689f38;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(104, 159, 56, 0.16);
  color: #689f38;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #689f38;
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(104, 159, 56, 0.04);
  border-color: transparent;
  color: #689f38;
}
.p-button.p-button-success.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(104, 159, 56, 0.16);
  border-color: transparent;
  color: #689f38;
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  color: #212529;
  background: #fbc02d;
  border: 1px solid #fbc02d;
}
.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #fab710;
  color: #212529;
  border-color: #fab710;
}
.p-button.p-button-warning:enabled:focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #fde6ab;
}
.p-button.p-button-warning:enabled:active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #e8a704;
  color: #212529;
  border-color: #e8a704;
}
.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #fbc02d;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(251, 192, 45, 0.04);
  color: #fbc02d;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(251, 192, 45, 0.16);
  color: #fbc02d;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #fbc02d;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(251, 192, 45, 0.04);
  border-color: transparent;
  color: #fbc02d;
}
.p-button.p-button-warning.p-button-text:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(251, 192, 45, 0.16);
  border-color: transparent;
  color: #fbc02d;
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #9c27b0;
  border: 1px solid #9c27b0;
}
.p-button.p-button-help:enabled:hover,
.p-buttonset.p-button-help > .p-button:enabled:hover,
.p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: #8c239e;
  color: #ffffff;
  border-color: #8c239e;
}
.p-button.p-button-help:enabled:focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #df9eea;
}
.p-button.p-button-help:enabled:active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help > .p-button:enabled:active {
  background: #7d1f8d;
  color: #ffffff;
  border-color: #7d1f8d;
}
.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #9c27b0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(156, 39, 176, 0.04);
  color: #9c27b0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  color: #9c27b0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #9c27b0;
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(156, 39, 176, 0.04);
  border-color: transparent;
  color: #9c27b0;
}
.p-button.p-button-help.p-button-text:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  border-color: transparent;
  color: #9c27b0;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #d32f2f;
  border: 1px solid #d32f2f;
}
.p-button.p-button-danger:enabled:hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #c02929;
  color: #ffffff;
  border-color: #c02929;
}
.p-button.p-button-danger:enabled:focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #edacac;
}
.p-button.p-button-danger:enabled:active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #aa2424;
  color: #ffffff;
  border-color: #aa2424;
}
.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #d32f2f;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(211, 47, 47, 0.04);
  color: #d32f2f;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(211, 47, 47, 0.16);
  color: #d32f2f;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #d32f2f;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(211, 47, 47, 0.04);
  border-color: transparent;
  color: #d32f2f;
}
.p-button.p-button-danger.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(211, 47, 47, 0.16);
  border-color: transparent;
  color: #d32f2f;
}

.p-button.p-button-link {
  color: #0b7ad1;
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #0b7ad1;
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: transparent;
}
.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #0b7ad1;
  border-color: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 2rem;
  height: 0.5rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #e3f2fd;
  color: #495057;
}

.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #495057;
  background: #e3f2fd;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #6c757d;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #2196f3;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #2196f3;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: #e9ecef;
  color: #2196f3;
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #2196f3;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #a6d5fa;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #e3f2fd;
  color: #495057;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #e3f2fd;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #e3f2fd;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-datatable .p-column-resizer-helper {
  background: #2196f3;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #f8f9fa;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #fcfcfc;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #e3f2fd;
  color: #495057;
}
.p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr:nth-child(even).p-highlight
  .p-row-toggler {
  color: #495057;
}
.p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr:nth-child(even).p-highlight
  .p-row-toggler:hover {
  color: #495057;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #e9ecef;
  border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}

.p-datascroller .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datascroller .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datascroller .p-datascroller-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datascroller .p-datascroller-content {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  padding: 0;
}
.p-datascroller.p-datascroller-inline .p-datascroller-list > li {
  border: solid #e9ecef;
  border-width: 0 0 1px 0;
}
.p-datascroller .p-datascroller-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.fc .fc-view-container th {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #495057;
}
.fc .fc-view-container td.fc-widget-content {
  border: 1px solid #dee2e6;
  color: #495057;
}
.fc .fc-view-container td.fc-head-container {
  border: 1px solid #dee2e6;
}
.fc .fc-view-container .fc-view {
  background: #ffffff;
}
.fc .fc-view-container .fc-row {
  border-right: 1px solid #dee2e6;
}
.fc .fc-view-container .fc-event {
  background: #0d89ec;
  border: 1px solid #0d89ec;
  color: #ffffff;
}
.fc .fc-view-container .fc-divider {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
}
.fc .fc-toolbar .fc-button {
  color: #ffffff;
  background: #2196f3;
  border: 1px solid #2196f3;
  font-size: 1rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.fc .fc-toolbar .fc-button:enabled:hover {
  background: #0d89ec;
  color: #ffffff;
  border-color: #0d89ec;
}
.fc .fc-toolbar .fc-button:enabled:active {
  background: #0b7ad1;
  color: #ffffff;
  border-color: #0b7ad1;
}
.fc .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: '';
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: '';
}
.fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #2196f3;
  border-color: #2196f3;
  color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #0d89ec;
  border-color: #0d89ec;
  color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  z-index: 1;
}
.fc .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.fc .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-orderlist .p-orderlist-controls {
  padding: 1rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
  font-weight: 600;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 0.5rem 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }
  .p-orderlist .p-orderlist-controls {
    padding: 1rem;
    flex-direction: row;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-orderlist .p-orderlist-controls .p-button:last-child {
    margin-right: 0;
  }
}
.p-organizationchart
  .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #e3f2fd;
  color: #495057;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #6cbbf5;
}
.p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 1rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-paginator {
  background: #ffffff;
  color: #6c757d;
  border: solid #e9ecef;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #495057;
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 2.357rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #e3f2fd;
  border-color: #e3f2fd;
  color: #495057;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #495057;
}

.p-picklist .p-picklist-buttons {
  padding: 1rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
  font-weight: 600;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-picklist .p-picklist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 0.5rem 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
}
.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column;
  }
  .p-picklist .p-picklist-buttons {
    padding: 1rem;
    flex-direction: row;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-picklist .p-picklist-buttons .p-button:last-child {
    margin-right: 0;
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
    content: '';
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
    content: '';
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
    content: '';
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
    content: '';
  }
}
.p-tree {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 1rem;
  border-radius: 4px;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 4px;
  transition: box-shadow 0.2s;
  padding: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #6c757d;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-checkbox
  .p-indeterminate
  .p-checkbox-icon {
  color: #495057;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #e3f2fd;
  color: #495057;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #495057;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #495057;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}

.p-treetable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
}
.p-treetable .p-sortable-column {
  outline-color: #a6d5fa;
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #495057;
  background: #e3f2fd;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #6c757d;
}
.p-treetable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #2196f3;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #2196f3;
}
.p-treetable .p-treetable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #a6d5fa;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable
  .p-treetable-tbody
  > tr
  > td
  .p-treetable-toggler
  + .p-checkbox
  .p-indeterminate
  .p-checkbox-icon {
  color: #495057;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #e3f2fd;
  color: #495057;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #495057;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #495057;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-treetable.p-treetable-hoverable-rows
  .p-treetable-tbody
  > tr:not(.p-highlight):hover
  .p-treetable-toggler {
  color: #495057;
}
.p-treetable .p-column-resizer-helper {
  background: #2196f3;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #f8f9fa;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-timeline .p-timeline-event-marker {
  border: 2px solid #2196f3;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
}
.p-timeline .p-timeline-event-connector {
  background-color: #dee2e6;
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 4px;
  transition: box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #495057;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #495057;
}
.p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-accordion .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion .p-accordion-tab .p-accordion-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion
  .p-accordion-tab:not(:first-child)
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link,
.p-accordion
  .p-accordion-tab:not(:first-child)
  .p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-accordion
  .p-accordion-tab:last-child
  .p-accordion-header:not(.p-highlight)
  .p-accordion-header-link {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-accordion
  .p-accordion-tab:last-child
  .p-accordion-header:not(.p-highlight)
  .p-accordion-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-card {
  background: #ffffff;
  color: #495057;
  box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-card .p-card-body {
  padding: 1rem;
}
.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #6c757d;
}
.p-card .p-card-content {
  padding: 1rem 0;
}
.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}

.p-fieldset {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-radius: 4px;
}
.p-fieldset .p-fieldset-legend {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 4px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem;
  color: #495057;
  border-radius: 4px;
  transition: box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}
.p-fieldset .p-fieldset-content {
  padding: 1rem;
}

.p-divider .p-divider-content {
  background-color: #ffffff;
}
.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px #dee2e6;
}
.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px #dee2e6;
}
.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

.p-panel .p-panel-header {
  border: 1px solid #dee2e6;
  padding: 1rem;
  background: #f8f9fa;
  color: #495057;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1rem;
}
.p-panel .p-panel-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0 none;
}

.p-splitter {
  border: 1px solid #dee2e6;
  background: #ffffff;
  border-radius: 4px;
  color: #495057;
}
.p-splitter .p-splitter-gutter {
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  background: #f8f9fa;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: #dee2e6;
}
.p-splitter .p-splitter-gutter-resizing {
  background: #dee2e6;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #f8f9fa;
  border: 0 none;
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #6c757d;
  padding: 1rem;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #6c757d;
  color: #6c757d;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #2196f3;
  color: #2196f3;
}
.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1rem;
  border: 0 none;
  color: #495057;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-toolbar {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-radius: 4px;
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-confirm-popup {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-confirm-popup .p-confirm-popup-content {
  padding: 1rem;
}
.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0 1rem 1rem 1rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}
.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #ffffff;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: #ffffff;
}
.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  border-radius: 4px;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 1.5rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 2rem 1.5rem;
}
.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 1.5rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-overlaypanel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #2196f3;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #0d89ec;
  color: #ffffff;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #ffffff;
}

.p-sidebar {
  background: #ffffff;
  color: #495057;
  padding: 1rem;
  border: 0 none;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-sidebar .p-sidebar-close,
.p-sidebar .p-sidebar-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-sidebar .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-sidebar .p-sidebar-close:focus,
.p-sidebar .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-sidebar-mask.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-tooltip .p-tooltip-text {
  background: #495057;
  color: #ffffff;
  padding: 0.5rem 0.5rem;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #495057;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #495057;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #495057;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #495057;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #f8f9fa;
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: #0d89ec;
  color: #ffffff;
  border-color: #0d89ec;
}
.p-fileupload-choose:not(.p-disabled):active {
  background: #0b7ad1;
  color: #ffffff;
  border-color: #0b7ad1;
}

.p-breadcrumb {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #495057;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #495057;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #6c757d;
}

.p-contextmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-contextmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-megamenu {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 4px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: #495057;
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: #6c757d;
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: #6c757d;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: #495057;
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-icon,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: #6c757d;
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-submenu-icon,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: #6c757d;
}
.p-megamenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-megamenu .p-megamenu-submenu {
  padding: 0.25rem 0;
  width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.25rem 0;
}

.p-menu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}
.p-menu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-menubar {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}
.p-menubar .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 4px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: #495057;
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: #6c757d;
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: #6c757d;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: #6c757d;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: #6c757d;
}
.p-menubar .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border-radius: 50%;
    transition:
      background-color 0.2s,
      color 0.2s,
      box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: #6c757d;
    background: #e9ecef;
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #495057;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: #495057;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: #6c757d;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: #6c757d;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #a6d5fa;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar
    .p-menubar-root-list
    .p-submenu-list
    .p-menuitem-active
    > .p-menuitem-link
    > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 4px;
  transition: box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #495057;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #495057;
}
.p-panelmenu .p-panelmenu-content {
  padding: 0.25rem 0;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: #495057;
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: #6c757d;
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: #6c757d;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}
.p-panelmenu
  .p-panelmenu-panel:not(:first-child)
  .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover
  > a,
.p-panelmenu
  .p-panelmenu-panel:not(:first-child)
  .p-panelmenu-header:not(.p-disabled).p-highlight:hover
  > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-slidemenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-slidemenu .p-slidemenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #e9ecef;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #495057;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon,
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #6c757d;
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 0.75rem 1rem;
  color: #495057;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 4px;
  background: #ffffff;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #495057;
  border: 1px solid #e9ecef;
  background: #ffffff;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #6c757d;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #e3f2fd;
  color: #495057;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #495057;
}
.p-steps .p-steps-item:before {
  content: ' ';
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #6c757d;
  padding: 1rem;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #ffffff;
  border-color: #6c757d;
  color: #6c757d;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #2196f3;
  color: #2196f3;
}

.p-tieredmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-tieredmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-inline-message {
  padding: 0.5rem 0.5rem;
  margin: 0;
  border-radius: 4px;
}
.p-inline-message.p-inline-message-info {
  background: #b3e5fc;
  border: solid #0891cf;
  border-width: 0px;
  color: #044868;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #044868;
}
.p-inline-message.p-inline-message-success {
  background: #c8e6c9;
  border: solid #439446;
  border-width: 0px;
  color: #224a23;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #224a23;
}
.p-inline-message.p-inline-message-warn {
  background: #ffecb3;
  border: solid #d9a300;
  border-width: 0px;
  color: #6d5100;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #6d5100;
}
.p-inline-message.p-inline-message-error {
  background: #ffcdd2;
  border: solid #e60017;
  border-width: 0px;
  color: #73000c;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #73000c;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
  border-radius: 4px;
}
.p-message .p-message-wrapper {
  padding: 1rem 1.5rem;
}
.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-message.p-message-info {
  background: #b3e5fc;
  border: solid #0891cf;
  border-width: 0 0 0 6px;
  color: #044868;
}
.p-message.p-message-info .p-message-icon {
  color: #044868;
}
.p-message.p-message-info .p-message-close {
  color: #044868;
}
.p-message.p-message-success {
  background: #c8e6c9;
  border: solid #439446;
  border-width: 0 0 0 6px;
  color: #224a23;
}
.p-message.p-message-success .p-message-icon {
  color: #224a23;
}
.p-message.p-message-success .p-message-close {
  color: #224a23;
}
.p-message.p-message-warn {
  background: #ffecb3;
  border: solid #d9a300;
  border-width: 0 0 0 6px;
  color: #6d5100;
}
.p-message.p-message-warn .p-message-icon {
  color: #6d5100;
}
.p-message.p-message-warn .p-message-close {
  color: #6d5100;
}
.p-message.p-message-error {
  background: #ffcdd2;
  border: solid #e60017;
  border-width: 0 0 0 6px;
  color: #73000c;
}
.p-message.p-message-error .p-message-icon {
  color: #73000c;
}
.p-message.p-message-error .p-message-close {
  color: #73000c;
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.p-toast {
  opacity: 0.9;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 6px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-toast .p-toast-message.p-toast-message-info {
  background: #b3e5fc;
  border: solid #0891cf;
  border-width: 0 0 0 6px;
  color: #044868;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #044868;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #c8e6c9;
  border: solid #439446;
  border-width: 0 0 0 6px;
  color: #224a23;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #224a23;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #ffecb3;
  border: solid #d9a300;
  border-width: 0 0 0 6px;
  color: #6d5100;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #6d5100;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #ffcdd2;
  border: solid #e60017;
  border-width: 0 0 0 6px;
  color: #73000c;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #73000c;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #f8f9fa;
  width: 4rem;
  height: 4rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #f8f9fa;
}
.p-galleria .p-galleria-item-nav {
  background: transparent;
  color: #f8f9fa;
  width: 4rem;
  height: 4rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  border-radius: 4px;
  margin: 0 0.5rem;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.1);
  color: #f8f9fa;
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #f8f9fa;
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #dee2e6;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #e3f2fd;
  color: #495057;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
.p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator,
.p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem
  .p-galleria-indicators
  .p-galleria-indicator.p-highlight
  button {
  background: #e3f2fd;
  color: #495057;
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #f8f9fa;
  width: 2rem;
  height: 2rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #f8f9fa;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
  transition: box-shadow 0.2s;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-galleria-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

.p-avatar {
  background-color: #dee2e6;
  border-radius: 4px;
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid #ffffff;
}

.p-chip {
  background-color: #dee2e6;
  color: #495057;
  border-radius: 16px;
  padding: 0 0.5rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -0.5rem;
  margin-right: 0.5rem;
}
.p-chip .p-chip-remove-icon {
  border-radius: 4px;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  margin-left: 0.5rem;
}
.p-chip .p-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
}
.p-scrolltop.p-link:hover {
  background: rgba(0, 0, 0, 0.8);
}
.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #f8f9fa;
}

.p-skeleton {
  background-color: #e9ecef;
  border-radius: 4px;
}
.p-skeleton:after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
}

.p-tag {
  background: #2196f3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
}
.p-tag.p-tag-success {
  background-color: #689f38;
  color: #ffffff;
}
.p-tag.p-tag-info {
  background-color: #0288d1;
  color: #ffffff;
}
.p-tag.p-tag-warning {
  background-color: #fbc02d;
  color: #212529;
}
.p-tag.p-tag-danger {
  background-color: #d32f2f;
  color: #ffffff;
}
.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}

.p-inplace .p-inplace-display {
  padding: 0.5rem 0.5rem;
  border-radius: 4px;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: #e9ecef;
  color: #495057;
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #dee2e6;
  border-radius: 4px;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #2196f3;
}
.p-progressbar .p-progressbar-label {
  color: #495057;
  line-height: 1.5rem;
}

.p-terminal {
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
}
.p-terminal .p-terminal-input {
  font-size: 1rem;
  font-family:
    'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
}

.p-blockui.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-badge {
  background: #2196f3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: #607d8b;
  color: #ffffff;
}
.p-badge.p-badge-success {
  background-color: #689f38;
  color: #ffffff;
}
.p-badge.p-badge-info {
  background-color: #0288d1;
  color: #ffffff;
}
.p-badge.p-badge-warning {
  background-color: #fbc02d;
  color: #212529;
}
.p-badge.p-badge-danger {
  background-color: #d32f2f;
  color: #ffffff;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-tag {
  background: #2196f3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
}
.p-tag.p-tag-success {
  background-color: #689f38;
  color: #ffffff;
}
.p-tag.p-tag-info {
  background-color: #0288d1;
  color: #ffffff;
}
.p-tag.p-tag-warning {
  background-color: #fbc02d;
  color: #212529;
}
.p-tag.p-tag-danger {
  background-color: #d32f2f;
  color: #ffffff;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #2196f3;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background-color: #2196f3;
}

/* theme-light ends ------------------------------------ */

/* layout-light.css -----------------starts*/

/* Add your customizations of the layout variables here */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src:
    local('Nunito Regular'),
    local('Nunito-Regular'),
    url('assets/nunito-v12-latin-ext_latin-regular.woff') format('woff');
  /* Modern Browsers */
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src:
    local('Nunito SemiBold'),
    local('Nunito-SemiBold'),
    url('assets/nunito-v12-latin-ext_latin-600.woff') format('woff2');
  /* Modern Browsers */
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src:
    local('Nunito Bold'),
    local('Nunito-Bold'),
    url('assets/nunito-v12-latin-ext_latin-700.woff') format('woff2');
  /* Modern Browsers */
}
/* Utils */
.clearfix:after {
  content: ' ';
  display: block;
  clear: both;
}

.card {
  background: #ffffff;
  padding: 1.25rem;
  border: 0 none;
  margin-bottom: 2rem;
  box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.card:last-child {
  margin-bottom: 0;
}
.card .card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.card .card-subtitle {
  color: #6c757d;
  font-weight: 600;
  margin: -1rem 0 1rem 0;
}

.p-toast.p-toast-top-right,
.p-toast.p-toast-top-left,
.p-toast.p-toast-top-center {
  top: 70px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

mark {
  background: #fff8e1;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
  font-family: monospace;
}

blockquote {
  margin: 1rem 0;
  padding: 0 2rem;
  border-left: 4px solid #90a4ae;
}

hr {
  border-top: solid #dee2e6;
  border-width: 1px 0 0 0;
  margin: 1rem 0;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.5;
}
p:last-child {
  margin-bottom: 0;
}

@-webkit-keyframes fade-in-up {
  from {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fade-out-down {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(0, 20px);
  }
}
@keyframes fade-out-down {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
.fade-in-up {
  -webkit-animation-name: fade-in-up;
  animation-name: fade-in-up;
}

.fade-out-down {
  -webkit-animation-name: fade-out-down;
  animation-name: fade-out-down;
}

@-webkit-keyframes fade-in-right {
  from {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fade-out-right {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(0, 20px);
  }
}
@keyframes fade-out-right {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
}
.fade-in-right {
  -webkit-animation-name: fade-in-right;
  animation-name: fade-in-right;
}

.fade-out-right {
  -webkit-animation-name: fade-out-right;
  animation-name: fade-out-right;
}

@-webkit-keyframes modal-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
@keyframes modal-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
.modal-in {
  -webkit-animation-name: modal-in;
  animation-name: modal-in;
}

.loader-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 100vh;
  background: #343a40;
}

.loader {
  position: relative;
  margin: auto;
}

.middle {
  top: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background: white;
  position: absolute;
  animation: middleAnimation 2s infinite linear;
  animation-delay: -100ms;
}

.bottom {
  top: 0;
  left: 0;
  position: absolute;
  height: 30px;
  width: 2px;
  background: white;
  border-radius: 2px;
  transform-origin: bottom;
  transform: rotate(45deg);
  animation: bottomAnimation 4s infinite;
}

.bottom:nth-child(1) {
  animation-delay: -1000ms;
}

.bottom:nth-child(2) {
  animation-delay: -2000ms;
}

.bottom:nth-child(3) {
  animation-delay: -3000ms;
}

.bottom-section {
  position: absolute;
  transform: translateY(-16px);
}

.top-section {
  position: absolute;
  transform: translateY(16px);
  transform: scaleY(-1);
}

@keyframes bottomAnimation {
  0% {
    transform: rotate(45deg) scaleY(1);
  }
  25% {
    transform: rotate(0) scaleY(0.7);
  }
  50% {
    transform: rotate(-45deg) scaleY(1);
  }
  75% {
    transform: rotate(0) scaleY(0.7);
  }
  100% {
    transform: rotate(45deg) scaleY(1);
  }
}
@keyframes middleAnimation {
  0% {
    transform: translate(-19px, -9px) scaleX(1);
  }
  25% {
    transform: translate(-19px, -9px) scaleX(0.6);
  }
  50% {
    transform: translate(-19px, -9px) scaleX(1);
  }
  75% {
    transform: translate(-19px, -9px) scaleX(0.7);
  }
  100% {
    transform: translate(-19px, -9px) scaleX(1);
  }
}
* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 14px;
}

body {
  font-family:
    'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-weight: 400;
  color: #495057;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background: #f8f9fa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #1976d2;
}

.layout-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
  animation-fill-mode: forwards;
}

.layout-ajax-loader-icon {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  font-size: 2rem;
  color: #6c757d;
}

.layout-topbar {
  background: #ffffff;
  height: 4rem;
  padding: 0 2rem;
  border-bottom: 1px solid #dee2e6;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #495057;
}
.layout-topbar .topbar-left {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.layout-topbar .menu-button {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  color: #495057;
  overflow: hidden;
  border-radius: 4px;
  background: #f8f9fa;
  transition:
    background-color 0.2s,
    box-shadow 0.2s;
  display: block;
  cursor: pointer;
  outline: 0 none;
}
.layout-topbar .menu-button:hover {
  background: #e9ecef;
}
.layout-topbar .menu-button:focus {
  box-shadow: 0 0 0 0.2rem #bbdefb;
}
.layout-topbar .menu-button i {
  line-height: inherit;
  font-size: 1.5rem;
}
.layout-topbar .topbar-separator {
  display: block;
  width: 1px;
  border-left: 1px solid #dee2e6;
  align-self: stretch;
  margin: 0 1rem;
}
.layout-topbar .mobile-logo {
  display: none;
}
.layout-topbar .viewname {
  font-weight: 600;
  font-size: 1.25rem;
}
.layout-topbar .topbar-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.layout-topbar .topbar-menu > li {
  margin-left: 1rem;
  position: relative;
}
.layout-topbar .topbar-menu > li:first {
  margin-left: 0;
}
.layout-topbar .topbar-menu > li.profile-item {
  padding: 0 1rem 0 1rem;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
.layout-topbar .topbar-menu > li.profile-item > a,
.layout-topbar .topbar-menu > li.profile-item > .p-link {
  width: auto;
  height: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0 0.5rem 0 0;
}
.layout-topbar .topbar-menu > li.profile-item .profile-image {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
}
.layout-topbar .topbar-menu > li.right-sidebar-item > a,
.layout-topbar .topbar-menu > li.right-sidebar-item > .p-link {
  background: #f8f9fa;
}
.layout-topbar .topbar-menu > li > a,
.layout-topbar .topbar-menu > li > .p-link {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  color: #495057;
  overflow: hidden;
  border-radius: 4px;
  transition:
    background-color 0.2s,
    box-shadow 0.2s;
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  outline: 0 none;
}
.layout-topbar .topbar-menu > li > a:hover,
.layout-topbar .topbar-menu > li > .p-link:hover {
  background: #e9ecef;
}
.layout-topbar .topbar-menu > li > a:focus,
.layout-topbar .topbar-menu > li > .p-link:focus {
  box-shadow: 0 0 0 0.2rem #bbdefb;
}
.layout-topbar .topbar-menu > li > a i,
.layout-topbar .topbar-menu > li > .p-link i {
  line-height: inherit;
  font-size: 1.5rem;
}
.layout-topbar .topbar-menu > li > a .topbar-badge,
.layout-topbar .topbar-menu > li > .p-link .topbar-badge {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.714rem;
  border-radius: 50%;
  font-weight: 600;
  background: #1976d2;
  color: #ffffff;
}
.layout-topbar .topbar-menu > li > ul {
  display: none;
  position: absolute;
  background: #ffffff;
  list-style: none;
  margin: 0;
  padding: 1rem;
  top: 3.25rem;
  right: 0;
  z-index: 999;
  min-width: 250px;
  border: 0 none;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  animation-duration: 0.12s;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  border-radius: 4px;
  transform-origin: center top;
}
.layout-topbar .topbar-menu > li > ul li a,
.layout-topbar .topbar-menu > li > ul li .p-link {
  color: #495057;
  padding: 0.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  transition: background-color 0.2s;
  cursor: pointer;
  width: 100%;
}
.layout-topbar .topbar-menu > li > ul li a:hover,
.layout-topbar .topbar-menu > li > ul li .p-link:hover {
  background: #e9ecef;
}
.layout-topbar .topbar-menu > li > ul.notifications-menu .notification-item {
  margin-left: 0.5rem;
}
.layout-topbar .topbar-menu > li > ul.notifications-menu .notification-summary {
  font-weight: 600;
}
.layout-topbar .topbar-menu > li > ul.notifications-menu .notification-detail {
  color: #6c757d;
}
.layout-topbar .topbar-menu > li > ul.profile-menu span {
  margin-left: 0.5rem;
}
.layout-topbar .topbar-menu > li.active-menuitem > ul {
  display: block;
}

.layout-sidebar {
  width: 16rem;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  user-select: none;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-sidebar .logo {
  text-align: center;
  display: block;
  outline: 0 none;
  padding: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  transition:
    background-color 0.2s,
    box-shadow 0.2s;
}
.layout-sidebar .logo-image {
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.layout-sidebar .app-name {
  vertical-align: middle;
  font-size: 1.5rem;
  letter-spacing: 0.2px;
}

.layout-menu-container {
  overflow: auto;
  flex: 1;
  padding: 1rem;
}

.layout-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.layout-menu .menu-separator {
  margin: 1rem 0;
}
.layout-menu .layout-root-menuitem > .layout-menuitem-root-text {
  font-size: 0.857rem;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.5rem 0 1rem 0;
}
.layout-menu .layout-root-menuitem > a,
.layout-menu .layout-root-menuitem > .p-link {
  display: none;
}
.layout-menu .layout-root-menuitem .layout-menu-tooltip {
  display: none;
}
.layout-menu .layout-root-menuitem .layout-menu-enter {
  max-height: 0;
}
.layout-menu .layout-root-menuitem .layout-menu-enter-active {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}
.layout-menu .layout-root-menuitem .layout-menu-enter-done {
  transform: none;
}
.layout-menu .layout-root-menuitem .layout-menu-exit {
  max-height: 1000px;
}
.layout-menu .layout-root-menuitem .layout-menu-exit-active {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
}
.layout-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.layout-menu ul a,
.layout-menu ul .p-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  outline: 0 none;
  cursor: pointer;
  transition:
    background-color 0.2s,
    box-shadow 0.2s;
  width: 100%;
}
.layout-menu ul a .layout-menuitem-icon,
.layout-menu ul .p-link .layout-menuitem-icon {
  margin-right: 0.5rem;
}
.layout-menu ul a .layout-submenu-toggler,
.layout-menu ul .p-link .layout-submenu-toggler {
  font-size: 75%;
  margin-left: auto;
  transition: transform 0.2s;
}
.layout-menu ul a.rotated-icon .layout-menuitem-icon,
.layout-menu ul .p-link.rotated-icon .layout-menuitem-icon {
  transform: rotate(90deg);
}
.layout-menu ul a.active-route,
.layout-menu ul .p-link.active-route {
  font-weight: 700;
}
.layout-menu ul li.active-menuitem {
  border-radius: 4px;
}
.layout-menu ul li.active-menuitem > a .layout-submenu-toggler,
.layout-menu ul li.active-menuitem > .p-link .layout-submenu-toggler {
  transform: rotate(-180deg);
}
.layout-menu ul li.active-menuitem .layout-menu-enter-from {
  max-height: 0;
}
.layout-menu ul li.active-menuitem .layout-menu-enter-to {
  max-height: 1000px;
}
.layout-menu ul li.active-menuitem .layout-menu-enter-active {
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}
.layout-menu ul ul {
  overflow: hidden;
  border-radius: 4px;
}
.layout-menu ul ul li a,
.layout-menu ul ul li .p-link {
  padding-left: 1.5rem;
}
.layout-menu ul ul li li a,
.layout-menu ul ul li li .p-link {
  padding-left: 2.5rem;
}
.layout-menu ul ul li li li a,
.layout-menu ul ul li li li .p-link {
  padding-left: 3.5rem;
}
.layout-menu ul ul li li li li a,
.layout-menu ul ul li li li li .p-link {
  padding-left: 4.5rem;
}
.layout-menu ul ul li li li li li a,
.layout-menu ul ul li li li li li .p-link {
  padding-left: 5.5rem;
}
.layout-menu ul ul li li li li li li a,
.layout-menu ul ul li li li li li li .p-link {
  padding-left: 6.5rem;
}

@media screen and (min-width: 992px) {
  .layout-wrapper.layout-slim .topbar-left .menu-button {
    display: none;
  }
  .layout-wrapper.layout-slim .topbar-left .topbar-separator {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-sidebar {
    width: 6rem;
    overflow: visible;
    z-index: 999;
  }
  .layout-wrapper.layout-slim .layout-sidebar .logo-image {
    margin-right: 0;
  }
  .layout-wrapper.layout-slim .layout-sidebar .app-name {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-sidebar .layout-menu-container {
    overflow: visible;
  }
  .layout-wrapper.layout-slim .layout-content-wrapper {
    margin-left: 6rem;
  }
  .layout-wrapper.layout-slim .layout-menu ul {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu li.active-menuitem > ul {
    display: block;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem {
    position: relative;
    border-radius: 4px;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > .layout-menuitem-root-text {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > a,
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > .p-link {
    display: block;
    text-align: center;
    border-radius: 4px;
    padding: 0.5rem 0;
    cursor: pointer;
    outline: none;
    transition: background-color 0.2s;
    width: 100%;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > a .layout-submenu-toggler,
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > .p-link .layout-submenu-toggler {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > a .layout-menuitem-icon,
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > .p-link .layout-menuitem-icon {
    font-size: 1.25rem;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > a .layout-menuitem-text,
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > .p-link .layout-menuitem-text {
    font-size: 0.875rem;
    display: block;
    margin-top: 0.25rem;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > ul {
    position: absolute;
    left: 5rem;
    top: 0;
    min-width: 15rem;
    border: 0 none;
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 1rem;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > ul.layout-menu-enter {
    opacity: 0;
    transform: translateX(20px);
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > ul.layout-menu-enter-active {
    opacity: 1;
    transform: none;
    transition:
      opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1),
      transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > ul.layout-menu-exit {
    opacity: 1;
    transform: none;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > ul.layout-menu-exit-active {
    opacity: 0;
    transform: translateX(20px);
    transition:
      opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1),
      transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }
}
.layout-sidebar-blue .layout-sidebar {
  background: #1976d2;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #1976d2 10%, #1565c0 100%);
}
.layout-sidebar-blue .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-blue .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-blue .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-blue .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-blue .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-blue .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: #ffffff;
}
.layout-sidebar-blue .layout-sidebar .layout-menu li a,
.layout-sidebar-blue .layout-sidebar .layout-menu li .p-link {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-blue .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-blue .layout-sidebar .layout-menu li .p-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-blue .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-blue .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-blue .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-blue .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-blue .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-blue.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #1976d2;
    background-image: linear-gradient(180deg, #1976d2 10%, #1565c0 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-blue .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-bluegray .layout-sidebar {
  background: #455a64;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #455a64 10%, #37474f 100%);
}
.layout-sidebar-bluegray .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-bluegray .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-bluegray .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-bluegray .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: #ffffff;
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu li a,
.layout-sidebar-bluegray .layout-sidebar .layout-menu li .p-link {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-bluegray .layout-sidebar .layout-menu li .p-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-bluegray .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-bluegray
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-bluegray
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  > ul
  > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-bluegray.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #455a64;
    background-image: linear-gradient(180deg, #455a64 10%, #37474f 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-bluegray .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-brown .layout-sidebar {
  background: #5d4037;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #5d4037 10%, #4e342e 100%);
}
.layout-sidebar-brown .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-brown .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-brown .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-brown .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-brown .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-brown .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: #ffffff;
}
.layout-sidebar-brown .layout-sidebar .layout-menu li a,
.layout-sidebar-brown .layout-sidebar .layout-menu li .p-link {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-brown .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-brown .layout-sidebar .layout-menu li .p-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-brown .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-brown .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-brown
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-brown .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-brown .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-brown.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #5d4037;
    background-image: linear-gradient(180deg, #5d4037 10%, #4e342e 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-brown .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-cyan .layout-sidebar {
  background: #0097a7;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #0097a7 10%, #00838f 100%);
}
.layout-sidebar-cyan .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-cyan .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-cyan .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-cyan .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-cyan .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-cyan .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: #ffffff;
}
.layout-sidebar-cyan .layout-sidebar .layout-menu li a,
.layout-sidebar-cyan .layout-sidebar .layout-menu li .p-link {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-cyan .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-cyan .layout-sidebar .layout-menu li .p-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-cyan .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-cyan .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-cyan .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-cyan .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-cyan .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-cyan.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #0097a7;
    background-image: linear-gradient(180deg, #0097a7 10%, #00838f 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-cyan .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-green .layout-sidebar {
  background: #388e3c;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #388e3c 10%, #388e3c 100%);
}
.layout-sidebar-green .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-green .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-green .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-green .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-green .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-green .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: #ffffff;
}
.layout-sidebar-green .layout-sidebar .layout-menu li a,
.layout-sidebar-green .layout-sidebar .layout-menu li .p-link {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-green .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-green .layout-sidebar .layout-menu li .p-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-green .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-green .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-green
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-green .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-green .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-green.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #388e3c;
    background-image: linear-gradient(180deg, #388e3c 10%, #388e3c 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-green .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-indigo .layout-sidebar {
  background: #303f9f;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #303f9f 10%, #283593 100%);
}
.layout-sidebar-indigo .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-indigo .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-indigo .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-indigo .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-indigo .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-indigo .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: #ffffff;
}
.layout-sidebar-indigo .layout-sidebar .layout-menu li a,
.layout-sidebar-indigo .layout-sidebar .layout-menu li .p-link {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-indigo .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-indigo .layout-sidebar .layout-menu li .p-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-indigo .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-indigo .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-indigo
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-indigo .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-indigo
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  > ul
  > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-indigo.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #303f9f;
    background-image: linear-gradient(180deg, #303f9f 10%, #283593 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-indigo .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-deeppurple .layout-sidebar {
  background: #512da8;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #512da8 10%, #4527a0 100%);
}
.layout-sidebar-deeppurple .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-deeppurple .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-deeppurple .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-deeppurple .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: #ffffff;
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu li a,
.layout-sidebar-deeppurple .layout-sidebar .layout-menu li .p-link {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-deeppurple .layout-sidebar .layout-menu li .p-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-deeppurple .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-deeppurple
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-deeppurple
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  > ul
  > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-deeppurple.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #512da8;
    background-image: linear-gradient(180deg, #512da8 10%, #4527a0 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-deeppurple .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-orange .layout-sidebar {
  background: #f57c00;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #f57c00 10%, #ef6c00 100%);
}
.layout-sidebar-orange .layout-sidebar .logo {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.layout-sidebar-orange .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-orange .layout-sidebar .app-name {
  color: rgba(0, 0, 0, 0.8);
}
.layout-sidebar-orange .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.layout-sidebar-orange .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-orange .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: rgba(0, 0, 0, 0.9);
}
.layout-sidebar-orange .layout-sidebar .layout-menu li a,
.layout-sidebar-orange .layout-sidebar .layout-menu li .p-link {
  color: rgba(0, 0, 0, 0.8);
}
.layout-sidebar-orange .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-orange .layout-sidebar .layout-menu li .p-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-orange .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-orange .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-orange
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  .layout-menuitem-root-text {
  color: rgba(0, 0, 0, 0.6);
}
.layout-sidebar-orange .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-orange
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  > ul
  > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-orange.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #f57c00;
    background-image: linear-gradient(180deg, #f57c00 10%, #ef6c00 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-orange .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-pink .layout-sidebar {
  background: #c2185b;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #c2185b 10%, #ad1457 100%);
}
.layout-sidebar-pink .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-pink .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-pink .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-pink .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-pink .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-pink .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: #ffffff;
}
.layout-sidebar-pink .layout-sidebar .layout-menu li a,
.layout-sidebar-pink .layout-sidebar .layout-menu li .p-link {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-pink .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-pink .layout-sidebar .layout-menu li .p-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-pink .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-pink .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-pink .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-pink .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-pink .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-pink.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #c2185b;
    background-image: linear-gradient(180deg, #c2185b 10%, #ad1457 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-pink .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-purple .layout-sidebar {
  background: #7b1fa2;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #7b1fa2 10%, #6a1b9a 100%);
}
.layout-sidebar-purple .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-purple .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-purple .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-purple .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-purple .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-purple .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: #ffffff;
}
.layout-sidebar-purple .layout-sidebar .layout-menu li a,
.layout-sidebar-purple .layout-sidebar .layout-menu li .p-link {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-purple .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-purple .layout-sidebar .layout-menu li .p-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-purple .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-purple .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-purple
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-purple .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-purple
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  > ul
  > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-purple.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #7b1fa2;
    background-image: linear-gradient(180deg, #7b1fa2 10%, #6a1b9a 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-purple .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-teal .layout-sidebar {
  background: #00796b;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #00796b 10%, #00695c 100%);
}
.layout-sidebar-teal .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-teal .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-teal .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-teal .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-teal .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-teal .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: #ffffff;
}
.layout-sidebar-teal .layout-sidebar .layout-menu li a,
.layout-sidebar-teal .layout-sidebar .layout-menu li .p-link {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-teal .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-teal .layout-sidebar .layout-menu li .p-link:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-teal .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-teal .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-teal .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-teal .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-teal .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-teal.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #00796b;
    background-image: linear-gradient(180deg, #00796b 10%, #00695c 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-teal .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-white .layout-sidebar {
  background: #ffffff;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #ffffff 10%, #ffffff 100%);
}
.layout-sidebar-white .layout-sidebar .logo {
  border-bottom: 1px solid #dee2e6;
}
.layout-sidebar-white .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-white .layout-sidebar .app-name {
  color: #495057;
}
.layout-sidebar-white .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid #dee2e6;
}
.layout-sidebar-white .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-white .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: #495057;
}
.layout-sidebar-white .layout-sidebar .layout-menu li a,
.layout-sidebar-white .layout-sidebar .layout-menu li .p-link {
  color: #495057;
}
.layout-sidebar-white .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-white .layout-sidebar .layout-menu li .p-link:hover {
  background: #e9ecef;
}
.layout-sidebar-white .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-white .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-white
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  .layout-menuitem-root-text {
  color: #6c757d;
}
.layout-sidebar-white .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: #ffffff;
}
.layout-sidebar-white .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: #ffffff;
}
@media screen and (min-width: 992px) {
  .layout-sidebar-white.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #ffffff;
    background-image: linear-gradient(180deg, #ffffff 10%, #ffffff 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-white .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-darkgray .layout-sidebar {
  background: #343a40;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #343a40 10%, #2e3439 100%);
}
.layout-sidebar-darkgray .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-darkgray .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-darkgray .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu li.active-menuitem > a,
.layout-sidebar-darkgray .layout-sidebar .layout-menu li.active-menuitem > .p-link {
  color: #ffffff;
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu li a,
.layout-sidebar-darkgray .layout-sidebar .layout-menu li .p-link {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu li a:hover,
.layout-sidebar-darkgray .layout-sidebar .layout-menu li .p-link:hover {
  background: #4b545c;
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu li a:focus,
.layout-sidebar-darkgray .layout-sidebar .layout-menu li .p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-darkgray
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: #3f474e;
}
.layout-sidebar-darkgray
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  > ul
  > li.active-menuitem {
  background: #3f474e;
}
@media screen and (min-width: 992px) {
  .layout-sidebar-darkgray.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #343a40;
    background-image: linear-gradient(180deg, #343a40 10%, #2e3439 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-darkgray .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-right {
  width: 16rem;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  padding: 1rem;
  overflow: auto;
  background: #ffffff;
  z-index: 999;
  border-left: 0 none;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-sidebar-right.layout-sidebar-right-active {
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  transform: translateX(0);
}

/* Right Sidebar Demo Content */
.layout-sidebar-right p {
  font-size: 0.875rem;
  color: #6c757d;
}
.layout-sidebar-right .withdraw {
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.layout-sidebar-right .withdraw > div {
  padding: 0.5rem;
}
.layout-sidebar-right .withdraw input,
.layout-sidebar-right .withdraw button {
  font-size: 0.875rem;
}

.layout-content-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 100vh;
  transition: margin-left 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-content {
  padding: 2rem;
  flex: 1 1 auto;
  -ms-flex: 1 1 auto;
}

.layout-footer {
  background: #ffffff;
  height: 4rem;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 0 2rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.layout-footer .footer-logo-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.layout-footer .footer-logo-container img {
  width: 1.5rem;
  height: 1.5rem;
}
.layout-footer .footer-logo-container .app-name {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-left: 0.5rem;
}
.layout-footer .copyright {
  font-size: 0.875rem;
  color: #6c757d;
}

@media screen and (min-width: 992px) {
  .layout-wrapper.layout-static .layout-content-wrapper {
    margin-left: 16rem;
  }
  .layout-wrapper.layout-static-inactive .layout-topbar .menu-button i:before {
    content: '';
  }
  .layout-wrapper.layout-static-inactive .layout-sidebar {
    transform: translateX(-100%);
  }
  .layout-wrapper.layout-static-inactive .layout-content-wrapper {
    margin-left: 0;
  }
  .layout-wrapper.layout-overlay .layout-topbar .menu-button i:before {
    content: '';
  }
  .layout-wrapper.layout-overlay .layout-content-wrapper {
    margin-left: 0;
  }
  .layout-wrapper.layout-overlay .layout-sidebar {
    z-index: 999;
    transform: translateX(-100%);
    box-shadow: none;
  }
  .layout-wrapper.layout-overlay.layout-overlay-active .layout-sidebar {
    transform: translateX(0);
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
}
@media screen and (max-width: 991px) {
  .blocked-scroll {
    overflow: hidden;
  }

  .layout-wrapper .layout-topbar {
    height: 7.125rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 998;
    box-shadow:
      0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .layout-wrapper .layout-topbar .topbar-left {
    width: 100%;
    padding: 0.5rem 0;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
  }
  .layout-wrapper .layout-topbar .topbar-left .topbar-separator {
    display: none;
  }
  .layout-wrapper .layout-topbar .topbar-right {
    width: 100%;
    padding: 0.5rem 0;
  }
  .layout-wrapper .layout-topbar .menu-button i:before {
    content: '';
  }
  .layout-wrapper .layout-topbar .mobile-logo {
    display: block;
    width: 2rem;
    height: 2rem;
  }
  .layout-wrapper .layout-topbar .viewname {
    font-size: 1rem;
  }
  .layout-wrapper .layout-topbar .topbar-menu {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li {
    margin-left: 0;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li.profile-item {
    border-left: 0;
    border-right: 0;
    padding: 0;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li.profile-item > a,
  .layout-wrapper .layout-topbar .topbar-menu > li.profile-item > .p-link {
    padding: 0;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li.profile-item .profile-image {
    width: 2rem;
    height: 2rem;
    margin: 0;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li.profile-item .profile-name {
    display: none;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li > ul {
    top: 2.75rem;
  }
  .layout-wrapper .layout-content-wrapper {
    margin-left: 0;
    padding-top: 7.125rem;
  }
  .layout-wrapper .layout-sidebar {
    z-index: 999;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
    transform: translateX(-100%);
  }
  .layout-wrapper.layout-mobile-active .layout-sidebar {
    transform: translateX(0);
  }
  .layout-wrapper.layout-mobile-active .layout-mask {
    display: block;
  }
  .layout-wrapper .layout-search .search-container {
    width: 75vw;
  }
}
@media screen and (max-width: 576px) {
  .layout-wrapper .layout-topbar .topbar-menu > li {
    position: static;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li > ul {
    position: fixed;
    top: 7.125rem;
    left: 0;
    width: 100%;
    right: auto;
  }
}
.layout-search {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  pointer-events: none;
}
.layout-search .search-container {
  background: #adb5bd;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 2rem;
  width: 50vw;
  border-radius: 0.625rem;
  position: relative;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  pointer-events: all;
}
.layout-search .search-container input {
  appearance: none;
  font-size: 1.5rem;
  text-indent: 2.5rem;
  padding: 0.5rem;
  width: 100%;
}
.layout-search .search-container i {
  color: #6c757d;
  width: 2rem;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
  margin-left: 1rem;
}
.layout-search .search-container-enter {
  opacity: 0;
  transform: translateY(20px);
}
.layout-search .search-container-enter-active {
  opacity: 1;
  transform: none;
  transition:
    opacity 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99),
    transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-search .search-container-exit {
  opacity: 1;
  transform: none;
}
.layout-search .search-container-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition:
    opacity 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99),
    transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-dashboard {
  /* Responsive */
}
.layout-dashboard .orders h4 {
  margin-bottom: 20px;
}
.layout-dashboard .orders .p-button {
  margin-top: -20px;
}
.layout-dashboard .orders .order-tabs {
  margin-bottom: 1rem;
}
.layout-dashboard .orders .order-tabs .order-tab {
  padding: 1rem 2rem 2rem 1rem;
  position: relative;
  transition: box-shadow 0.2s;
}
.layout-dashboard .orders .order-tabs .order-tab:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.layout-dashboard .orders .order-tabs .order-tab i {
  font-size: 1rem;
  vertical-align: middle;
}
.layout-dashboard .orders .order-tabs .order-tab .order-label {
  margin-left: 0.25rem;
  vertical-align: middle;
}
.layout-dashboard .orders .order-tabs .order-tab .stat-detail-icon {
  position: absolute;
  right: 1rem;
  top: 2.25rem;
  height: 1rem;
  width: 1rem;
}
.layout-dashboard .orders .order-tabs .order-tab img {
  position: absolute;
  bottom: 0;
  left: 5%;
  width: 90%;
}
.layout-dashboard .overview-chart {
  overflow: auto;
}
.layout-dashboard .dashbard-demo-dropdown {
  min-width: 8rem;
  margin-left: auto;
}
.layout-dashboard .product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.layout-dashboard .product-badge.status-instock {
  background: #c8e6c9;
  color: #256029;
}
.layout-dashboard .product-badge.status-outofstock {
  background: #ffcdd2;
  color: #c63737;
}
.layout-dashboard .product-badge.status-lowstock {
  background: #feedaf;
  color: #8a5340;
}
.layout-dashboard .p-datatable-products .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
@media screen and (max-width: 960px) {
  .layout-dashboard .p-datatable.p-datatable-products .p-datatable-thead > tr > th,
  .layout-dashboard .p-datatable.p-datatable-products .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  .layout-dashboard .p-datatable.p-datatable-products .p-datatable-tbody > tr {
    border-bottom: 1px solid var(--surface-d);
  }
  .layout-dashboard .p-datatable.p-datatable-products .p-datatable-tbody > tr > td {
    text-align: left;
    display: flex;
    align-items: center;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }
  .layout-dashboard .p-datatable.p-datatable-products .p-datatable-tbody > tr > td .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4rem 1rem -0.4rem -0.4rem;
    font-weight: bold;
  }
}

.login-body {
  background: #f8f9fa;
  color: #495057;
}
.login-body .login-wrapper {
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
}
.login-body .login-wrapper .login-panel {
  width: 30%;
  height: 100%;
  text-align: center;
  padding: 40px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-direction: column;
  flex-direction: column;
}
.login-body .login-wrapper .login-panel .logo {
  height: 50px;
}
.login-body .login-wrapper .login-panel .login-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}
.login-body .login-wrapper .login-panel .login-form > p {
  font-weight: 600;
  margin: 0;
  color: #6c757d;
  margin-bottom: 32px;
}
.login-body .login-wrapper .login-panel .login-form > p > a {
  color: #1976d2;
  cursor: pointer;
}
.login-body .login-wrapper .login-panel .login-form > .p-password {
  width: 85%;
  max-width: 310px;
  margin-bottom: 20px;
}
.login-body .login-wrapper .login-panel .login-form > .p-password .p-password-input {
  width: 100%;
}
.login-body .login-wrapper .login-panel .login-form > input {
  width: 85%;
  max-width: 310px;
  margin-bottom: 20px;
}
.login-body .login-wrapper .login-panel .login-form > button {
  width: 85%;
  max-width: 310px;
}
.login-body .login-wrapper .login-panel p {
  font-weight: 600;
  margin: 0;
  color: #6c757d;
}
.login-body .login-wrapper .login-panel p > a {
  color: #1976d2;
}
.login-body .login-wrapper .login-image {
  width: 70%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 40px 20px;
}
.login-body .login-wrapper .login-image .login-image-content > h1 {
  font-size: 60px;
  margin: 0;
  color: #ffffff;
}
.login-body .login-wrapper .login-image .login-image-content h3 {
  font-size: 24px;
  color: #ffffff;
  margin: 0;
  font-weight: 600;
  margin-top: 32px;
}
.login-body .login-wrapper .login-image .image-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.login-body .login-wrapper .login-image .image-footer > p {
  font-weight: 600;
  margin: 0;
  color: #ffffff;
  margin-right: 20px;
}
.login-body .login-wrapper .login-image .image-footer .icons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.login-body .login-wrapper .login-image .image-footer .icons i {
  font-size: 22px;
  color: #2196f3;
  margin-right: 20px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: opacity 0.2s;
  background: #ffffff;
  opacity: 0.7;
}
.login-body .login-wrapper .login-image .image-footer .icons i:hover {
  opacity: 1;
}

@media (min-width: 993px) {
  .login-body .login-wrapper .login-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('assets/bg-login.jpg');
  }
}
@media (max-width: 992px) {
  .login-body .login-wrapper .login-panel {
    width: 100%;
    z-index: 2;
  }
  .login-body .login-wrapper .login-image {
    display: none;
  }
}
.invoice {
  padding: 2rem;
}
.invoice .invoice-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.invoice .invoice-company .logo-image {
  width: 75px;
  margin-bottom: 0.5rem;
}
.invoice .invoice-company div {
  margin-bottom: 0.5rem;
}
.invoice .invoice-company .company-name {
  font-weight: 700;
  font-size: 1.5rem;
}
.invoice .invoice-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: right;
}
.invoice .invoice-details {
  width: 15rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.invoice .invoice-details > div {
  width: 50%;
  margin-bottom: 0.5rem;
}
.invoice .invoice-details .invoice-label {
  text-align: left;
  font-weight: 700;
}
.invoice .invoice-details .invoice-value {
  text-align: right;
}
.invoice .invoice-to {
  margin-top: 1.5rem;
  padding-top: 2rem;
  border-top: 1px solid #dee2e6;
}
.invoice .invoice-to .bill-to {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.invoice .invoice-to .invoice-to-info div {
  margin-bottom: 0.5rem;
}
.invoice .invoice-items {
  margin-top: 2rem;
  padding-top: 2rem;
}
.invoice .invoice-items table {
  width: 100%;
  border-collapse: collapse;
}
.invoice .invoice-items table tr {
  border-bottom: 1px solid #dee2e6;
}
.invoice .invoice-items table th {
  font-weight: 700;
}
.invoice .invoice-items table th,
.invoice .invoice-items table td {
  padding: 1rem;
  text-align: right;
}
.invoice .invoice-items table th:first-child,
.invoice .invoice-items table td:first-child {
  text-align: left;
}
.invoice .invoice-summary {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 2.5rem;
  padding-top: 2.5rem;
}
.invoice .invoice-summary .invoice-value {
  font-weight: 700;
}

@media print {
  body * {
    visibility: hidden;
  }

  #invoice-content * {
    visibility: visible;
  }

  #invoice-content {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
  }
}
.help-page p {
  margin: 0;
}
.help-page .help-search {
  background: url('assets/bg-help.png') repeat;
  padding: 0;
  text-align: center;
}
.help-page .help-search .help-search-content {
  padding: 5rem 12rem;
}
.help-page .help-search .search-container {
  font-size: 1.5rem;
  padding: 1rem;
  position: relative;
}
.help-page .help-search .search-container input {
  appearance: none;
  font-size: 1.5rem;
  text-indent: 2.5rem;
  padding: 0.5rem;
  width: 100%;
}
.help-page .help-search .search-container i {
  color: #495057;
  width: 2rem;
  position: absolute;
  margin-left: 1rem;
  top: 50%;
  margin-top: -0.5rem;
}
.help-page .status-bars {
  margin-top: 1rem;
  display: -ms-flexbox;
  display: flex;
}
.help-page .status-bars .status-bar {
  flex: 1 1 0;
  -ms-flex: 1 1 0px;
  background: #8bc34a;
  height: 50px;
  margin-right: 0.25rem;
  transition: transform 0.2s;
}
.help-page .status-bars .status-bar:last-child {
  margin-right: 0;
}
.help-page .status-bars .status-bar.status-bar-failure {
  background: #ec407a;
}
.help-page .status-bars .status-bar:hover {
  transform: scale(1.1);
}
.help-page .status-bar-footer {
  padding: 1rem 0 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.help-page .blog-post {
  height: 150px;
  border-radius: 4px;
  margin: 3rem 2rem;
  position: relative;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.help-page .blog-post:last-child {
  margin-bottom: 1rem;
}
.help-page .blog-post img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.help-page .blog-post .blog-text {
  position: absolute;
  left: 20px;
  top: 30px;
}
.help-page .blog-post .blog-text h1 {
  font-size: 1.25rem;
  color: #ffffff;
  margin-bottom: 1rem;
  font-weight: 700;
}
.help-page .blog-post .blog-text span {
  color: #ffffff;
  font-weight: 600;
}
.help-page .blog-post .blog-profile {
  position: absolute;
  top: -25px;
  left: -25px;
}
.help-page .blog-post .blog-profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.help-page .blog-post:nth-child(1) {
  background-image: url('assets/blog1.jpg');
}
.help-page .blog-post:nth-child(2) {
  background-image: url('assets/blog2.jpg');
}
.help-page .blog-post:nth-child(3) {
  background-image: url('assets/blog3.jpg');
}

@media screen and (max-width: 991px) {
  .help-page .help-search .help-search-content {
    padding: 6rem 2rem;
  }
}
.widget-overview-box {
  min-height: 6rem;
  position: relative;
  border-left: 4px solid;
}
.widget-overview-box .overview-title {
  font-weight: 600;
  font-size: 1.25rem;
  vertical-align: middle;
  margin-left: 0.5rem;
}
.widget-overview-box .overview-icon {
  display: inline-block;
  border-radius: 4px;
  width: 2em;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  vertical-align: middle;
}
.widget-overview-box .overview-icon i {
  font-size: 1rem;
  line-height: inherit;
}
.widget-overview-box .overview-detail {
  text-align: center;
  margin-top: 1rem;
}
.widget-overview-box .overview-detail .p-col-6 {
  padding: 0.5rem;
}
.widget-overview-box .overview-number {
  font-size: 1.5rem;
  font-weight: 600;
}
.widget-overview-box .overview-subtext {
  color: #6c757d;
  font-weight: 600;
  margin-top: 0.25rem;
}
.widget-overview-box .p-col-6:first-child {
  border-right: 1px solid #dee2e6;
}
.widget-overview-box.widget-overview-box-1 {
  border-left-color: #64b5f6;
}
.widget-overview-box.widget-overview-box-1 .overview-title {
  color: #64b5f6;
}
.widget-overview-box.widget-overview-box-1 .overview-icon {
  background: #64b5f6;
  color: #1976d2;
}
.widget-overview-box.widget-overview-box-2 {
  border-left-color: #7986cb;
}
.widget-overview-box.widget-overview-box-2 .overview-title {
  color: #7986cb;
}
.widget-overview-box.widget-overview-box-2 .overview-icon {
  background: #7986cb;
  color: #303f9f;
}
.widget-overview-box.widget-overview-box-3 {
  border-left-color: #4db6ac;
}
.widget-overview-box.widget-overview-box-3 .overview-title {
  color: #4db6ac;
}
.widget-overview-box.widget-overview-box-3 .overview-icon {
  background: #4db6ac;
  color: #00796b;
}
.widget-overview-box.widget-overview-box-4 {
  border-left-color: #4dd0e1;
}
.widget-overview-box.widget-overview-box-4 .overview-title {
  color: #4dd0e1;
}
.widget-overview-box.widget-overview-box-4 .overview-icon {
  background: #4dd0e1;
  color: #0097a7;
}

.widget-tasks .task {
  padding: 1rem 0;
}
.widget-tasks .task-name {
  margin-bottom: 0.5rem;
}
.widget-tasks .task-name span {
  font-weight: 600;
}
.widget-tasks .task-progress {
  border-radius: 4px;
  height: 0.5rem;
  background: #dee2e6;
}
.widget-tasks .task-progress > div {
  border-radius: 4px;
  height: 100%;
}
.widget-tasks .task-1 .task-progress > div {
  background: #64b5f6;
  width: 75%;
}
.widget-tasks .task-2 .task-progress > div {
  background: #a5d6a7;
  width: 60%;
}
.widget-tasks .task-3 .task-progress > div {
  background: #7986cb;
  width: 80%;
}
.widget-tasks .task-4 .task-progress > div {
  background: #9575cd;
  width: 40%;
}
.widget-tasks .task-5 .task-progress > div {
  background: #4db6ac;
  width: 50%;
}

.widget-image-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.widget-image-list li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #dee2e6;
  padding: 1rem;
}
.widget-image-list li:first-child {
  border-top: 0 none;
  font-weight: 600;
  font-size: 1rem;
}
.widget-image-list li img {
  width: 64px;
  vertical-align: middle;
  margin-right: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.widget-image-list li .listitem-value {
  font-weight: 600;
}

.widget-feature-box {
  height: 100%;
  text-align: center;
  padding: 2rem;
}
.widget-feature-box > img {
  margin-bottom: 32px;
}
.widget-feature-box > h2 {
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 8px;
  color: #495057;
}
.widget-feature-box span {
  padding: 0 20px;
  font-size: 14px;
  display: block;
  color: #6c757d;
}

.widget-pricing-card {
  border-radius: 4px;
  background: #1f8ce3;
  color: #ffffff;
  text-align: center;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
.widget-pricing-card.deal {
  background-image: linear-gradient(45deg, #105da1 0%, #1f8ce3 100%);
}
.widget-pricing-card > span {
  display: block;
  font-size: 1.25rem;
  margin: 0 0 0.5rem;
}
.widget-pricing-card > ul {
  list-style-type: none;
  padding: 0;
}
.widget-pricing-card > ul > li {
  display: block;
  font-size: 1rem;
  margin: 0 0 0.5rem;
  margin-bottom: 0.75rem;
}
.widget-pricing-card > a {
  padding: 0.5rem 2rem;
  margin-top: 1.75rem;
  display: block;
  color: #ffffff;
  margin: 1.75rem auto 0 auto;
  font-weight: 600;
  width: 10rem;
  border-radius: 4px;
  transition: background-color 0.2s;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.widget-pricing-card > a > span {
  display: block;
  padding: 0;
}
.widget-pricing-card > a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.widget-timeline .timeline-event {
  padding: 0 1rem 1rem 1rem;
  border-left: 1px solid #dee2e6;
  position: relative;
  margin-left: 0.5rem;
}
.widget-timeline .timeline-event-icon {
  position: absolute;
  left: 0;
  top: 0.125rem;
  transform: translateX(-50%);
  color: #6c757d;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  color: #ffffff;
  text-align: center;
}
.widget-timeline .timeline-event-icon i {
  line-height: inherit;
}
.widget-timeline .timeline-event-title {
  font-weight: 600;
  margin: 0 0 0.5rem 0.5rem;
}
.widget-timeline .timeline-event-detail {
  font-size: 0.875rem;
  color: #6c757d;
  margin-left: 0.5rem;
}

.widget-chat ul {
  padding: 1rem;
  margin: 0;
  list-style-type: none;
}
.widget-chat ul li {
  padding: 0.5rem 0;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
}
.widget-chat ul li img {
  width: 36px;
}
.widget-chat ul li div {
  padding: 0.5rem 1rem;
  margin: 0.25rem 0;
  border-radius: 4px;
}
.widget-chat ul li.message-from {
  justify-content: flex-start;
}
.widget-chat ul li.message-from img {
  margin-right: 0.5rem;
}
.widget-chat ul li.message-from div {
  order: 2;
  color: #121212;
  background: #dff0d8;
  border: solid 1px #d6e9c6;
}
.widget-chat ul li.message-own {
  justify-content: flex-end;
}
.widget-chat ul li.message-own img {
  margin-left: 0.5rem;
  order: 2;
}
.widget-chat ul li.message-own div {
  order: 1;
  color: #121212;
  background: #d9edf7;
  border: solid 1px #bce8f1;
}
.widget-chat .new-message {
  height: 40px;
  border-top: 1px solid #dee2e6;
  color: #afafc0;
}
.widget-chat .new-message .message-attachment {
  display: inline-block;
  border-right: 1px solid #dee2e6;
  width: 40px;
  line-height: 40px;
  height: 100%;
  text-align: center;
}
.widget-chat .new-message .message-attachment i {
  line-height: inherit;
  font-size: 24px;
}
.widget-chat .new-message .message-input {
  position: relative;
  top: -4px;
  width: calc(100% - 100px);
  display: inline-block;
}
.widget-chat .new-message .message-input input {
  border: 0 none;
  font-size: 14px;
  width: 100%;
  background: transparent;
  outline: 0 none;
  padding-left: 8px;
}

.widget-person-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.widget-person-list > li {
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
}
.widget-person-list > li:last-child {
  border-bottom: 0 none;
}
.widget-person-list > li .person-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.widget-person-list > li .person-item img {
  margin-right: 0.5rem;
  width: 4rem;
  height: 4rem;
}
.widget-person-list > li .person-item .person-name {
  font-size: 1.25rem;
  font-weight: 700;
}
.widget-person-list > li .person-item .person-subtext {
  color: #6c757d;
}
.widget-person-list > li .person-actions button {
  margin-right: 0.5rem;
}
.widget-person-list > li .person-actions button:last-child {
  margin-right: 0;
}

.widget-user-card {
  padding: 0;
  text-align: center;
}
.widget-user-card .user-card-header {
  position: relative;
  height: 200px;
  background-image: url('assets/user-card.jpg');
  background-size: cover;
}
.widget-user-card .user-card-avatar {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 8rem;
  height: 8rem;
  margin-left: -4rem;
  margin-bottom: -4rem;
  border-radius: 50%;
}
.widget-user-card .user-card-body {
  padding: 6em 2rem 2rem 2rem;
}
.widget-user-card .user-card-title {
  font-size: 1.5rem;
  font-weight: 700;
}
.widget-user-card .user-card-subtext {
  color: #6c757d;
}
.widget-user-card .user-card-stats {
  margin-top: 2rem;
}
.widget-user-card .user-card-stats i {
  font-size: 2rem;
  color: #6c757d;
}
.widget-user-card .user-card-stats span {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.exception-body {
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
}
.exception-body .logo {
  width: 50px;
  position: absolute;
  left: 50px;
  top: 50px;
}
.exception-body .exception-content .exception-title {
  font-size: 112px;
  font-weight: 700;
  color: #ffffff;
}
.exception-body .exception-content .exception-detail {
  font-weight: 700;
  color: #ffffff;
  margin: 10px 0 0 0;
}
.exception-body .exception-content > a {
  margin-top: 40px;
  width: 250px;
  display: inline-block;
  text-align: center;
  padding: 0.5rem 1rem;
  color: #ffffff;
  transition: background-color 0.2s;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}
.exception-body .exception-content > a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.exception-body.access {
  background-image: url('assets/bg-access.jpg');
}
.exception-body.notfound {
  background-image: url('assets/bg-404.jpg');
}
.exception-body.error {
  background-image: url('assets/bg-error.jpg');
}

@media (max-width: 640px) {
  .exception-body .exception-content .exception-title {
    font-size: 72px;
  }
}
.layout-config {
  width: 16rem;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  padding: 1rem;
  overflow: auto;
  background: #ffffff;
  z-index: 999;
  border-left: 0 none;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-config.layout-config-active {
  transform: translateX(0);
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.layout-config.layout-config-active .layout-config-content .layout-config-button i {
  transform: rotate(360deg);
}
.layout-config p {
  line-height: 1.5rem;
  color: #6c757d;
}
.layout-config .layout-themes {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.layout-config .layout-themes > div {
  padding: 0.25rem;
}
.layout-config .layout-themes a,
.layout-config .layout-themes .p-link {
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  display: block;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: transform 0.2s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.layout-config .layout-themes a i,
.layout-config .layout-themes .p-link i {
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}
.layout-config .layout-themes a:hover,
.layout-config .layout-themes .p-link:hover {
  transform: scale(1.1);
}

.layout-config-button.p-button {
  position: fixed;
  top: 40%;
  right: 0;
  width: 3.5rem;
  height: 3.5rem;
  text-align: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
  box-shadow: -0.25rem 0 1rem rgba(0, 0, 0, 0.15);
  transition: background-color 0.2s;
  background: #1976d2;
  color: #ffffff;
  line-height: 3rem;
  border-right: none;
}
.layout-config-button.p-button .p-button-icon {
  font-size: 2.5rem;
  line-height: inherit;
  transform: rotate(0deg);
  transition: transform 1s;
}

/* Add your customizations of the layout styles here */

/* layout-light.css -----------------ends */

/* override dialog footer and header */
.p-dialog .p-dialog-footer {
  background: #ececec;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}
.p-dialog .p-dialog-header {
  /* background: #ececec; */
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}
/* override footer logo styles */
.layout-footer .footer-logo-container img {
  width: 2.5rem;
  height: 2.5rem;
}

/* Pages CSS */
/* Image column */
.sport-page th:nth-of-type(1),
.comp-page th:nth-of-type(1),
.venue-page th:nth-of-type(1) {
  width: 120px;
}

/* Name column */
.venue-page th:nth-of-type(2) {
  /* width: 250px; */
}
/* Name column - Sport */
.sport-page th:nth-of-type(2) {
  /* width: 180px; */
}
/* Location column */
.venue-page th:nth-of-type(3) {
  width: 200px;
}
.sport-page th:nth-of-type(3) {
  width: 110px;
}

/* Segments and Special triggers  */
.sport-page th:nth-of-type(4) {
  width: 180px;
}
.sport-page tr,
.venue-page tr,
.comp-page tr,
.comp-page-root-view tr,
.advert-page tr,
.events-page tr,
.event-page tr,
.output-page tr,
.templates-page tr,
.advert-page-root-view tr {
  vertical-align: top;
}
/* Name column */

.sport-page th:nth-of-type(5) {
  width: 150px;
}

/* Actions column */
.sport-page .actions,
.users-page .actions,
.venue-page .actions,
.advert-page .actions,
.advertiser-page .actions,
.templates-page .actions {
  text-align: right;
}

.advertiserDialog .p-dialog-content {
  padding-bottom: 5px;
}
.advertiserDialog .p-dialog-footer {
  margin-left: -10px;
  margin-right: -10px;
}
.requestAdvertiserDialog {
  width: 800px;
}

/*
***************************************
* contacts-page (Contacts Page)
***************************************
*/
.contacts-page p {
  line-height: 1;
}

.contacts-page h6 {
  padding-top: 22px;
  margin-bottom: 24px;
  font-size: 1.2rem;
  font-weight: 600;
}
.contacts-page .itemName {
  color: #0769b7;
  font-weight: 600;
  font-size: 1.1rem;
}

.contacts-page .email {
  color: #0b7ad1;
}

/*
* end of contacts-page (Contacts Page)
*/

/*
***************************************
* advert-page (Adverts Page) and output-page
* (list of allocated ads and add allocations table)
***************************************
*/

.advert-page th:nth-of-type(1),
.advert-page-root-view th:nth-of-type(1),
.output-page th:nth-of-type(1) {
  width: 110px;
  padding-left: 8px !important;
  padding-right: 0 !important;
}
.advert-page th:nth-of-type(2),
.output-page th:nth-of-type(2) {
  /* width: 550px; */
  /* padding-right: 0;
padding-left: 0; */
}
/* Club (for NHL users only) */
.advert-page-root-view th:nth-of-type(3) {
  width: 60px;
  padding-left: 0px !important;
  padding-right: 0rem !important;
}
/* Club (for NHL users only) */
.advert-page-root-view tr td:nth-of-type(3) {
  width: 60px;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.2rem;
}
/* Thumbnail (Adverts page) */
.advert-page tr td:nth-of-type(2),
.advert-page-root-view tr td:nth-of-type(2) {
  padding-left: 0px;
  padding-right: 0px;
}
/* Format */
.advert-page th:nth-of-type(3),
.advert-page-root-view th:nth-of-type(4) {
  width: 80px;
  padding-left: 8px !important;
  padding-right: 0 !important;
}
.output-page th:nth-of-type(3) {
  width: 100px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
/* Duration */
/* .advert-page th:nth-of-type(4), .advert-page-root-view th:nth-of-type(5) {
width: 85px;
padding-left: 0 !important;
padding-right: 0 !important;
} */
/* approval page team */
.advert-page.approvals-page th:nth-of-type(3) {
  width: 80px;
  padding-left: 1.25rem !important;
  padding-right: 0rem !important;
}

/* Type (image/video) */
.advert-page th:nth-of-type(4),
.advert-page-root-view th:nth-of-type(5),
.advert-page-root-view.approvals-page th:nth-of-type(5),
.output-page th:nth-of-type(4) {
  width: 60px;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.advert-page.approvals-page th:nth-of-type(4) {
  width: 84px;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
/* Type (image/video) */
.advert-page.approvals-page tr td:nth-of-type(4),
.output-page tr td:nth-of-type(4),
.advert-page-root-view.approvals-page tr td:nth-of-type(5) {
  padding-left: 0px;
  padding-right: 0px;
}

/* Date/time column (Adverts page) */
.advert-page tr td:nth-of-type(4),
.advert-page-root-view tr td:nth-of-type(5),
.advert-page.approvals-page tr td:nth-of-type(4),
.advert-page.slot-virtual th:nth-of-type(4),
.advert-page-root-view.slot-virtual th:nth-of-type(4) {
  padding: 1rem 0;
  text-align: left;
}
/* Date/time column */
.advert-page th:nth-of-type(5),
.advert-page-root-view th:nth-of-type(6),
.advert-page-root-view.approvals-page th:nth-of-type(6),
.advert-page.slot-virtual th:nth-of-type(4),
.advert-page-root-view.slot-virtual th:nth-of-type(4) {
  width: 110px;
}
/* approvals date */
.advert-page.approvals-page th:nth-of-type(6) {
  width: 130px;
}
/* approvals date */
.advert-page.approvals-page th:nth-of-type(7) {
  width: 130px;
}

/* Approve/Decline approvals page column */
.advert-page.approvals-page th:nth-of-type(8),
.advert-page-root-view.approvals-page th:nth-of-type(8) {
  width: 260px;
}

/* actions (Adverts page) */
.advert-page tr td:nth-of-type(6),
.advert-page-root-view tr td:nth-of-type(7),
.output-page-root-view tr td:nth-of-type(5) {
  padding-left: 0px;
  padding-right: 0px;
}

.checkbox-column {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

/* NHL Hub nhl hub */
/* TODO: remove other slot-virtual above */
.advert-page.slot-virtual th:nth-of-type(1),
.advert-page-root-view.slot-virtual th:nth-of-type(1) {
  width: 200px;
  padding-left: 50px !important;
}
.advert-page.slot-virtual th:nth-of-type(2),
.advert-page-root-view.slot-virtual th:nth-of-type(2) {
  width: 350px;
}
.advert-page.slot-virtual tr td::nth-of-type(2),
.advert-page-root-view.slot-virtual tr td::nth-of-type(2) {
  padding-left: 60px !important;
}
.advert-page.slot-virtual th:nth-of-type(3),
.advert-page-root-view.slot-virtual th:nth-of-type(3) {
  width: auto;
}

.advert-page.slot-virtual th:nth-of-type(4),
.advert-page-root-view.slot-virtual th:nth-of-type(5) {
  width: 120px;
}
/* Slot virtual slot virtual actions */
.advert-page.slot-virtual tr td::nth-of-type(5),
.advert-page-root-view.slot-virtual tr td::nth-of-type(6) {
  padding: 1rem 0;
  text-align: left;
  width: 88px;
}

/* TODO: remove other slot-virtual above this block */

.executionCodePart {
  /* width: 35px; */
  /* display: inline-block; */
  font-family: Arial, Helvetica, sans-serif;
  float: left;
  color: black;
  letter-spacing: 0.1rem;
  /* display: inline; */
}
/* Allowed feeds multiselect */
.multiline-input .p-multiselect-label {
  text-wrap: balance;
}

.readOnlyBlock,
.readOnlyBlock .p-divider .p-divider-content {
  background-color: #f2f9ff;
  /* #f4f7fa; */
}

#advertDialog .p-dialog-content {
  padding-bottom: 0.75rem;
}
.fade-in-text {
  /* font-size: 150px; */

  /* animation: fadeIn linear 1s;
-webkit-animation: fadeIn linear 1s;
-moz-animation: fadeIn linear 1s;
-o-animation: fadeIn linear 1s;
-ms-animation: fadeIn linear 1s; */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* multiselect in advert page - multiple rows */
#advertDialog .p-multiselect-label,
.single-advert-page .p-multiselect-label,
.media-page .p-multiselect-label,
.advertiserDialog .p-multiselect-label,
.requestAdvertiserDialog .p-multiselect-label,
.pw_3d .p-multiselect-label {
  white-space: normal;
}
.p-multiselect-token-label {
  text-transform: uppercase;
}
.approvedAdRow {
  background: #fafffa !important;
  /* color: #495057 !important; */
  opacity: 0.7 !important;
}
.declinedAdRow {
  background: #fcf5f5 !important;
  opacity: 0.7 !important;
}
.approvedButton {
  width: 8em;
}
/* advertisements page / adverts page */
.advert-page .p-datatable-tbody tr:hover,
.advert-page-root-view .p-datatable-tbody tr:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}
.advertPageThumbColumn {
  max-width: 100%;
  max-height: 72px !important;
}
/* used in SV ads page */
.noAssetThumbColumn {
  opacity: 0.6;
  margin-left: 10px;
}
/* Size (px) (Output page, list of allocated ads) */
.output-page th:nth-of-type(3) :not(.allocateAdvertTable) th:nth-of-type(3) {
  width: 150px;
}
/* Type (Output page, list of allocated ads) */
.output-page th:nth-of-type(4) {
  width: 150px;
}
.allocateAdvertTable th:nth-of-type(2) {
  width: 50px;
}
/* align all columns in the center */
.allocateAdvertTable tr td,
.advert-page tr td,
.advert-page-root-view tr td {
  vertical-align: middle;
}

/* advert page table */
.single-advert-page table,
.media-page table {
  text-align: left;
}
.advertMetadataBlock th {
  font-weight: 200;
  color: #777;
  font-size: 0.8rem;
}
.single-advert-page tr {
  /* line-height: 5px; */

  /* vertical-align: top; */
}

.adPageSingleMessage {
  /* margin-bottom: 25px; */
  justify-content: space-between;
  display: flex;
  padding: 10px;
  vertical-align: middle;
}
.adPageSingleMessage:hover {
  background-color: #f8f8f8;
}
.single-advert-page .adAssetsDiv,
.single-advert-page .adAssetZonesDiv {
  height: 60px;
}
.messagesBlock {
  width: 100%;
  margin-top: 40px;
}
.single-advert-page .adZoneViewPart {
  background-color: #dae1ee;
  /* width: 175px; */
  width: 19.5%;
  height: 50px;
  border-right: 3px solid #f3f3f3;
  float: left;
}
.single-advert-page .adZoneViewPart.splitZone {
  width: 9.75%;
}
.single-advert-page .adZoneActive:hover :not(.adZoneText) {
  /* background-color: #aaa; */

  /* border: 2px  solid grey; */
  /* 6px  solid white 2px  solid white 2px solid white; */
  cursor: pointer;
  /* border: 1px solid #ccc; */
}
.single-advert-page .selectedZone {
  border: 2px solid #0288d1;
}
.single-advert-page .selectedZoneText {
  font-weight: 800;
  font-size: 1.2rem;
}
.single-advert-page .adZoneActive {
  background-color: #f6f6f6;
  /* border: 1px solid grey; */
  /* margin-right: 3px; */
  /* border: 2px  solid white 6px  solid white 2px  solid white 2px solid white; */
  /* cursor: pointer; */
}

.customUploadButton {
  width: 90%;
  height: 90%;
  display: inline !important;
  text-align: center;
  border-radius: 8px;
}

.advertMetadataBlock td {
  width: 100px;
}
.advertMetadataBlock .p-fieldset-content,
.advertFilesBlock .p-fieldset-content,
.tagsBlock .p-fieldset-content,
.actionButtonsBlock .p-fieldset-content {
  padding: 0 0.5rem 0.5rem 0.5rem;
}
.actionButtonsBlock .p-fieldset.p-component {
  padding-left: 0;
  padding-right: 0;
}
.infoButton:focus {
  /* outline:0 !important;
border: none;
outline: none; */
}
.infoButton:hover {
  cursor: auto;
  /* outline: 0 !important;
border: none;
outline:none; */
  background-color: #e9ecef !important;
  /* #fff !important; */
}
.buttonType {
  font-size: 0.8rem;
  padding: 0.1rem 0.35rem;
}
.smallButton {
}
.smallButton .p-button-icon-left {
  margin-right: 0.3rem;
}
.movedUp {
  position: relative;
  bottom: 1px;
}
.movedUp2 {
  position: relative;
  bottom: 2px;
}
.movedDown {
  position: relative;
  top: 1px;
}
.movedDown2 {
  position: relative;
  top: 2px;
}
.advertMetadataBlock {
  width: 500px;
}
.advertFilesBlock {
  width: 500px;
}
.tagsBlock {
  max-width: 400px;
}
.actionButtonsBlock {
  max-width: 360px;
  margin: 10px auto 60px auto;
}
.dividerDescription {
  font-weight: 500;
  font-size: 0.9rem;
}

.single-advert-page .p-fieldset .p-fieldset-legend,
.media-page .p-fieldset .p-fieldset-legend {
  border: none;
  background-color: #fff;
}
/* advert page table ends */

/* Media page media page */
.media-page .p-datatable .p-datatable-tbody > tr {
  height: auto;
}

.media-page .objectTable tr td:nth-of-type(1) {
  padding-left: 0px;
  padding-right: 0px;
  width: 140px;
}
.media-page tr th:nth-of-type(1) {
  padding-right: 0px;
  width: 140px;
}

/* modified */
.media-page .objectTable tr td:nth-of-type(2),
.media-page tr th:nth-of-type(2) {
  padding-left: 8px;
  padding-right: 0px;
  width: 70px;
}
/* image column */
.media-page .objectTable tr td:nth-of-type(3),
.media-page tr th:nth-of-type(3) {
  padding-top: 0px;
  padding-bottom: 0px;
}
.media-page .objectTable {
  /* width: 50vw; */
  width: 425px;
}
.media-page .thumbColumn {
  max-width: 175px;
  max-height: 20px !important;
}
.mediaPageRightSide {
  margin-left: 40px;
}
.media-page .advertMetadataBlock,
.media-page .advertFilesBlock,
.media-page .tagsBlock,
.media-page .actionButtonsBlock,
.media-page .advertInfoBlock {
  /* margin: 0 auto; */
}
/* media page ends */

/******************************
* advertiser-page
******************************
*/
.advertiser-page .p-datatable .p-datatable-tbody > tr,
.advertiser-page-user-view .p-datatable .p-datatable-tbody > tr {
  height: 80px;
}
/* 3-letter code */
.advertiser-page th:nth-of-type(1) {
  width: 90px;
}
/* 3-letter code */
.advertiser-page-user-view th:nth-of-type(1) {
  width: 110px;
}
/* created at (user view) */
.advertiser-page-user-view th:nth-of-type(3) {
  width: 140px;
}
/* name */
.advertiser-page th:nth-of-type(2) {
  width: 240px;
}
/* category */
.advertiser-page th:nth-of-type(3) {
  width: 380px;
}
.advertiser-page tr td:nth-of-type(3) {
  /* width: 50px; */
  padding-left: 0rem;
  padding-right: 0rem;
  /* text-align: center; */
}

/* created at */
.advertiser-page th:nth-of-type(5) {
  width: 110px;
}

/* actions */
.advertiser-page th:nth-of-type(6) {
  width: 110px;
}
/*
***************************************
*
* reporting-page / Reporting Page
*
***************************************
*/
.reporting-page *:focus {
  box-shadow: none !important;
}
.reporting-page th,
.reporting-page tr td {
  padding-left: 0.1rem !important;
  padding-right: 0rem !important;
}
/* MVP/NHL CPM toggle button */
.tabMenuContainer {
  display: flex;
  justify-content: space-between;
}
/* rotation, game clock, exposure threshod, exposure seconds, game link */
.reporting-page th:nth-of-type(6),
.reporting-page th:nth-of-type(7),
.reporting-page th:nth-of-type(8),
.reporting-page th:nth-of-type(9),
.reporting-page th:nth-of-type(14) {
  letter-spacing: -0.02rem;
  word-spacing: -1px;
}
.reporting-page th {
  font-size: 13px;
}
/* exposure threshold column */
.reporting-page th:nth-of-type(9) {
  letter-spacing: -0.03rem;
  padding-left: 0rem !important;
}
/* media values column */
.reporting-page th:nth-of-type(11) {
  padding-left: 0rem !important;
  padding-right: 0.1rem !important;
}
.border-text-element .p-dropdown-label.p-inputtext {
  padding: 0.36rem;
}
.bottom-row-label {
  margin-bottom: -4px !important;
}
.reporting-page .element-label {
  margin-bottom: -1px;
  font-weight: 600;
  /* ellipsify */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}
.reportingSecondsCell {
  width: 120px;
  text-align: center !important;
}
.bypassRow .reportingSecondsCell {
  background-color: #ff040417;
}
.backendRow .reportingSecondsCell {
  background-color: rgb(255 180 30 / 20%);
}
.noData .valuationCell {
  background-color: #e75bff1a;
}
.reporting-page #season .p-inputtext {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
/* json editor */
.rowMarkingStart {
  background-color: rgb(33 150 243 / 10%) !important;
}
/* make json editor header sticky */
.sticky-header.p-datatable .p-datatable-header {
  position: sticky;
  top: 0;
  z-index: 700;
}
/* audience-page / Audience Page */
.html-tooltip.p-tooltip .p-tooltip-text {
  background-color: #292929;
  width: 800px;
}
.audience-page th {
  padding-left: 0rem !important;
  padding-right: 0.1rem !important;
}
.audience-page tr td:nth-of-type(1) {
  padding-left: 0.5rem !important;
  padding-left: 0.5rem !important;
}
/* Event name column */
.audience-page th:nth-of-type(4) {
  padding-left: 1rem !important;
}
.totalAudienceCell {
  background-color: rgb(0 143 255 / 4%);
}
.CPMCell {
  background-color: rgb(225 239 0 / 3%);
}
.splitButton .p-button {
  border-radius: 0;
}
.splitButton .p-splitbutton-defaultbutton {
  padding: 0.5rem 0rem 0.5rem 1rem;
}
.extraDataColumn {
  width: 85px !important;
  text-align: center !important;
}
/*
***************************************
* comp-page / Competition Page
*
* comp-page-root-view (including league column)
***************************************
*/

/* Name column */
.comp-page-root-view th:nth-of-type(2) {
  width: 50px;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
/* League */
.comp-page-root-view tr td:nth-of-type(2) {
  width: 50px;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.2rem;
}
/* Date */
.comp-page-root-view th:nth-of-type(4),
.comp-page th:nth-of-type(3) {
  width: 210px;
}
/* Countries */
.comp-page-root-view th:nth-of-type(5),
.comp-page th:nth-of-type(4) {
  width: 160px;
}
/* Sport */
.comp-page-root-view th:nth-of-type(6),
.comp-page th:nth-of-type(5) {
  width: 140px;
}

/*
***************************************
* events-page / Competition Page
*
***************************************
*/

/* TODO! FIX .actions everywhere. name column shouldn't have fix width, other - should */
.events-page .actions,
.comp-page .actions,
.advert-page .actions,
.event-page .actions,
.output-page .actions,
.comp-page-root-view .actions {
  width: 110px;
}
.advert-page-root-view .actions {
  width: 88px;
}

/* image */
.events-page th:nth-of-type(1),
.comp-page th:nth-of-type(1),
.templates-page th:nth-of-type(1),
.comp-page-root-view th:nth-of-type(1) {
  width: 130px;
}
/* event name */
.events-page th:nth-of-type(2) {
  /* width: auto; */
}
/* Date & Time */
.events-page th:nth-of-type(3) {
  width: 120px;
  /* padding-left: 10px !important; */
  padding-right: 0 !important;
}
.events-page th:nth-of-type(4) {
  width: 190px;
}
/* outputs */
.events-page th:nth-of-type(5) {
  /* width: 160px; */
  /* width: auto; */
}

/*
***************************************
* event-page / single event page, outputs list
*
***************************************
*/

/* advertisers Column on Event page - in Output table */
.advertiserListColumn {
  padding-top: 0;
}

/* output name in outputs table (Event page) */
.event-page th:nth-of-type(1) {
  /* padding-top: 0px; */
  /* width: 400px; */
}
/* advertiser list in outputs table (Event page) */
.event-page tr td:nth-of-type(2) {
  padding-top: 0px;
}

/*
***************************************
* users-page
*
***************************************
*/

.users-page th:nth-of-type(1) {
  width: 320px;
}
/* League (Scope) */
.users-page th:nth-of-type(2) {
  width: 100px;
  padding-right: 0px;
}
.users-page tr td:nth-of-type(2) {
  padding-right: 0px;
}
/* OLD League */
/* .users-page th:nth-of-type(3)  {
  width: 120px;
} */
/* Status */
.users-page th:nth-of-type(3) {
  width: 100px;
}
/* Created */
.users-page th:nth-of-type(4) {
  width: 130px;
}
.users-page .advert-type-badge.NHL {
  background: #173656;
  color: #e7f6fe;
  opacity: 0.8;
}
.buttonBadgeUserPage {
  font-size: 1.3rem;
}
/* Users table User user */
.userDialog .p-dialog-content {
  height: 474px;
}
.advertListDialog th:nth-of-type(2) {
  width: 110px;
}
.advertListDialog .p-datatable-tbody tr {
  height: 110px !important;
  /* padding-bottom: 0px !important;
padding-top: 0px !important; */
}
.users-page .p-datatable-tbody > tr {
  height: 70px !important;
}
/* .venue-page td:nth-of-type(3) {
  vertical-align: top;

} */
/* .image-icon-overlay { text-align: center; } */
/* .upload-image-text { color: #1976D2; cursor: pointer;} */
/* .image-icon-overlay img { display: block; }
.image-icon-overlay .pi-upload { position: absolute; bottom:0; left:0; } */
.dialogImageBlock {
  min-height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialogImage {
  width: 150px;
  display: block;
  max-height: 150px;
}
.addAdvertiserDialog .dialogImage {
  width: 100px;
  max-height: 100px;
}
.events-page .dialogImage {
  width: 80px;
  max-height: 80px;
}
.events-page .dialogImageBlock {
  min-height: 80px;
}
/* .object-form-image-block img {

  display: inline-block;
} */
.imageControlButtonsBlock {
  /* text-align: center;
  display: inline-block; */
  display: block;
  position: absolute;
  left: 50px;
  transform: translateY(-50%);
  top: 50%;
}
.smallTableImage {
  height: 60px;
  max-width: 60px;
}
.advertiserColumn {
  display: flex;
  align-items: center;
  justify-content: left;
}
.eventPageImage {
  height: 120px;
  /* max-width: 60px; */
}
/* GENERAL general General */
/* remove any highlight or outline on input elements */
/* input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none !important;
}
.p-button:focus {
  outline: none;
} */
/* fix invisible border in Divider component */
.p-divider-solid.p-divider-horizontal:before {
  border-top-style: solid !important;
}

.p-divider-solid.p-divider-vertical:before {
  border-left-style: solid !important;
}
/* distance between content block and left side of the screen */
/* override App name font in topbar */
.layout-sidebar .app-name {
  font-size: 1.3rem;
}
/* override logo positioning */
.layout-sidebar .logo {
  /* margin-left: 0; */
  /* originally - 1rem, but we remove right-padding to save place for app-name */
  padding: 1rem 0rem 1rem 1rem;
}
.layout-sidebar .logo-image {
  width: 3.7rem;
  height: 3.7rem;
}
.layout-sidebar .logo-image.customer-logo {
  height: 3.9rem;
  margin-right: 2rem;
  margin-left: -1rem;
}
/* Hide scroll for Chrome */
.layout-sidebar ::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.layout-sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.layout-wrapper.layout-static .layout-content-wrapper {
  margin-left: 14rem;
}
.layout-wrapper.layout-static.layout-static-inactive .layout-content-wrapper {
  margin-left: 0rem;
}
/* width of vertical menu sidebar. Should be in sync with 'margin-left' distance above */
.layout-sidebar {
  width: 14rem;
}
/* make card occupy (almost) all the space between header to footer */
.card.card-container {
  min-height: calc(100vh - 13rem);
}
.ellipsify {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}
.centeredImportant {
  text-align: center !important;
}
.centered {
  text-align: center;
}
.cursorPointer {
  cursor: pointer;
}

/* dialog styling */
.styledDialog .p-dialog-header {
  background-color: #bfd6e8 !important;
  background: radial-gradient(circle, rgb(234 246 255) 35%, #c8e7ff 100%) !important;
  padding: 10px 12px 12px !important;
}
.styledDialog .p-dialog-header span {
  margin: 0 auto;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
  letter-spacing: 0.08rem;
  color: rgb(18 52 52);
}
/* .p-dialog .p-dialog-header .p-dialog-header-icon {
  background-color: #0097A7;
} */
.p-dialog .p-dialog-header .p-dialog-header-icon:hover {
  background: transparent !important;
}
.p-dialog-header-close-icon .pi .pi-times {
  color: rgb(18 52 52);
}

/* invalid input in dialog */
small .p-invalid {
  color: #d72323;
}

/* customize scrollbar in dropdown/multiselect
    primeReact components and in the Audience table */
.p-multiselect-items-wrapper::-webkit-scrollbar,
.p-dropdown-items-wrapper::-webkit-scrollbar,
.p-datatable-scrollable-body::-webkit-scrollbar {
  width: 0.85rem;
}

.p-multiselect-items-wrapper::-webkit-scrollbar-track,
.p-dropdown-items-wrapper::-webkit-scrollbar-track,
.p-datatable-scrollable-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(170, 144, 178, 0.3);
}

.p-multiselect-items-wrapper::-webkit-scrollbar-thumb,
.p-dropdown-items-wrapper::-webkit-scrollbar-thumb,
.p-datatable-scrollable-body::-webkit-scrollbar-thumb {
  background-color: #6f86bc21;
  outline: 1px solid #65b6ff57;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 140px;
  height: 140px;
}
.lds-ellipsis div {
  position: absolute;
  top: 88px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #a9b6de;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 18px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 56px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 96px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.image-type {
  border: 1px solid #23547b;
  color: #23547b;
}
.video-type {
  border: 1px solid #0d89ec;
  color: #0d89ec;
}
.root-type {
  background: #e4c3e6;
  color: #320536;
}
.league-badge.SUP {
  background: #2db5ca;
  color: #ffffff;
}
.league-badge.ISG {
  background: #15581e;
  color: #effff1;
  opacity: 0.8;
}
.league-badge.NHL {
  background: #173656;
  color: #e7f6fe;
  opacity: 0.8;
}
.league-badge.NHL:hover {
  background: #173656 !important;
  color: #e7f6fe !important;
}
.league-badge.FCB {
  background: #dc052d;
  color: #eee;
  opacity: 0.7;
}
.league-badge.GER {
  background: #fa3138;
  color: #000;
  opacity: 0.7;
}
.league-badge.MED {
  background: #383838;
  color: #fa9f91;
  opacity: 0.8;
}

.league-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  border: 1px solid #a1a6b7;
  font-size: 12px;
  letter-spacing: 0.3px;
  display: block;
  text-align: center;
}
.network-badge {
  border-radius: 4px;
  padding: 0.2em;
  text-transform: uppercase;
  font-weight: 700;
  border: 1px solid #ccc;
  font-size: 0.8rem;
  letter-spacing: 0.1px;
  text-align: center;
}
.tag-badge {
  /* border-radius: 5px;
border: 1px grey solid; */
  padding: 0.4em 0.8rem;
  text-transform: uppercase;
  /* font-weight: 700;
font-size: 12px;
letter-spacing: .3px; */
  /* display: block; */

  text-align: center;
}
.advert-type-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
/* Breadcrumb breadcrumb */
.topbar-left .layout-breadcrumb.viewname {
  text-transform: none;
  font-size: 0.9rem;
}
/* COMPETITION PAGE competition page */
.segmentBlock {
  /* transition: margin 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94); */
  transition: all 0.5s ease-in-out;
}
.toggleSegmentBlock {
  cursor: pointer;
  margin-top: 1.2rem;
  /* 8px */
  margin-bottom: 0.4rem;
  transition: 1s;
}
.toggleSegmentBlock:hover {
  color: #2196f3;
  transition: all 0.5s ease-in-out;
}

/* PLAYLIST PAGE playlist page */

.jexcel > tbody > tr > td {
  padding: 5px 4px 3px 4px !important;
}

/* 09.09.22 */
#p_zones .p-card .p-card-body {
  padding-top: 0px;
  padding-left: 4px;
}
/* 09.09.22 ends */
#p_zones .p-datatable .p-datatable-tbody > tr {
  height: auto;
}
#p_zones .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 0rem 0.5rem 0.5rem;
}
#p_zones {
  font-size: 0.9rem;
}
#p_zones .p-datatable {
  font-size: 0.9rem;
}
#p_zones .p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 0rem 0.5rem 0.5rem;
}
/* #p_zones .p-datatable .p-datatable-tbody > tr > td {
padding: 0.5rem 0rem 0.5rem 0.5rem;
} */
/* #p_zones th:nth-of-type(1) {
padding-left: 0.5rem !important;
} */
#p_zones th:nth-of-type(1) {
  width: 96px;
}
#p_zones th:nth-of-type(3) {
  width: 50px;
}
#p_zones th:nth-of-type(4) {
  width: 82px;
}

#schedule-adlist {
  width: 450px;
  padding: 0 7px 0 3px;
}
/* left scrollbar */

#schedule-adlist::-webkit-scrollbar,
.p-dialog-content::-webkit-scrollbar
/* #schedule-container::-webkit-scrollbar  */ {
  width: 0.75rem;
}

#schedule-adlist::-webkit-scrollbar-track,
#schedule-container::-webkit-scrollbar-track,
.p-dialog-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(31, 12, 78, 0.4);
}

#schedule-adlist::-webkit-scrollbar-thumb,
#schedule-container::-webkit-scrollbar-thumb,
.p-dialog-content::-webkit-scrollbar-thumb {
  background-color: rgba(146, 218, 252, 0.2);
  outline: 1px solid rgb(146, 218, 252, 0.7);
}
/* network list in the header */
.broadcaster-list::-webkit-scrollbar {
  height: 4px;
}
.broadcaster-list::-webkit-scrollbar-thumb {
  background: #2196f336;
}
.broadcaster-list {
  margin-top: 4px;
  font-weight: 400;
  overflow-x: auto;
  overflow-y: hidden;
}
.playlistLeftPart,
.playlistRightPart {
  display: inline-block;
}
.playlistLeftPart {
  margin-right: 20px;
  float: left;
}
.playlistHeader {
  margin-top: -10px;
}
.playlistHeaderLogo {
  position: absolute !important;
  max-height: 40px;
  left: 14px;
  top: 8px;
}
.playlistHeaderTitle,
.playlistHeaderButtons {
  display: inline-block;
}
.playlistHeaderTitle {
  width: 75%;
}
.schedule-adlist .jexcel:nth-of-type(1) colgroup col:first-child {
  width: 0 !important;
}

.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.headerRow {
  background-color: #3d8cb7 !important;
  color: #ffffff !important;
  font-weight: bold !important;
}
/* 'Clear' cell in the playlist manager */
.clearRow {
  padding: 0 !important;
}
.clearRow :hover {
  cursor: pointer;
}
/* empty cells in upcoming events after deadline */
.readonly.unfilledCell {
  background-color: rgba(246 133 109 / 18%) !important;
  border: 1px solid rgba(255 0 0 / 10%) !important;
}
.zone-modal {
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: #fbf9f5;
}
.zone-modal .modal-header {
  position: relative;
  z-index: 1000;
  display: flex;
  height: 88px;
}
.zone-modal .modal-header-old {
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  height: 88px;
}
.zone-modal .modal-header-menu {
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  height: 40px;
}
.zone-modal .modal-header .p-button {
  margin-left: 6px;
}
.zone-modal .btn-zone-help {
  padding-left: 30px;
}
.zone-modal .zone-modal-body {
  position: absolute;
  top: 148px;
  left: 0;
  right: 0;
  /* bottom: 0; */
  bottom: 40px;
  overflow: hidden;
  background-color: #fbf9f5;
}
.zone-modal.with-paste-mode .zone-modal-body {
  bottom: 72px;
}
.zone-modal.with-help-bar .zone-modal-body {
  top: 127px;
}
.zone-modal .zone-modal-body-no-footer {
  position: absolute;
  top: 148px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #fbf9f5;
}
.zone-modal.with-paste-mode .zone-modal-body-no-footer {
  bottom: 72px;
}
.zone-modal.with-help-bar .zone-modal-body-no-footer {
  top: 127px;
}
.zone-modal .schedule-adlist {
  float: none;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
}
.zone-modal .schedule-container {
  position: absolute;
  top: 0;
  left: 480px;
  right: 0;
  bottom: 0;
  margin: 0;
  overflow: auto;
}
.zone-modal.with-adlist-hidden .schedule-container {
  left: 2px;
}
.zone-modal.with-adlist-hidden .schedule-adlist {
  display: none;
}
.schedule-header-action-buttons {
  position: absolute;
  right: 20px;
}
.schedule-header-feeds {
  width: calc(100% - 845px);
  /* height: 48px; */
  /* background-color: #f7f7f7; */
}
.modal-header.p-card {
  box-shadow:
    0 2px 1px 0px rgb(0 0 0 / 20%),
    0 2px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
}
.schedule-tab-menu {
  margin: 0 auto;
  width: 534px;
}
.scrollmenu {
  /* background-color: #777; */
  overflow: auto;
  white-space: nowrap;
  margin-top: -3.45rem;
}
.scrollmenu::-webkit-scrollbar {
  height: 6px; /* height of horizontal scrollbar */
  width: 4px; /* width of vertical scrollbar */
  border: 1px solid lightblue;
}
.scrollmenu::-webkit-scrollbar:vertical {
  display: none;
}
.scrollmenu::-webkit-scrollbar-thumb:horizontal {
  background: rgb(151, 199, 242);
  background: radial-gradient(circle, rgba(151, 199, 242, 1) 35%, rgba(182, 217, 249, 1) 100%);
}
div.scrollmenu span {
  display: inline-block;
  /* color: white; */
  text-align: center;
  /* padding: 14px; */
  text-decoration: none;
}
.footer-pastebuffer {
  display: flex;
  gap: 2rem;
  position: fixed;
  justify-content: flex-end;
  align-items: center;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-height: 40px;
  background-color: #cce1ff;
  border-top: 1px solid #b3d1ff;
  color: #555555;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.footer-pastebuffer .adlabel {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  line-height: 14px;
  transition: padding 0.2s ease;
}
.footer-pastebuffer .adlabel:hover {
  padding-right: 25px;
  cursor: pointer;
}
.footer-pastebuffer .adlabel:hover:after {
  content: '\00D7';
  position: absolute;
  top: 0;
  right: 0;
  width: 13px;
  height: 13px;
  line-height: 10px;
  border-radius: 13px;
  margin: 2px 5px;
  background-color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  color: #333333;
}
.footer-pastebuffer.active {
  bottom: 44px;
}
/* .footer-pastebuffer .btn-clear {

  float: right;
  right: 0;
  padding: 0 5px;
  background-color: #e6f0ff;
  font-size: 12px;
} */
/* .footer-pastebuffer .btn-clear .key {
  border-color: #0088cc;
  color: #0088cc;
} */
.clearSelectionBtn {
  float: right;
  height: 30px;
}
.footer-bar {
  position: fixed;
  z-index: 1000;
  bottom: 4px;
  left: 4px;
  right: 4px;
  height: 20px;
  padding: 10px 20px 10px 310px;
  background-color: #fff;
  border-top: 1px solid #ddd;
}
.footer-bar .below-adlist {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 20px;
}

.key-container {
  display: inline-block;
}
.key-container.disabled {
  display: none;
}
.key-container .key {
  display: inline-block;
  padding: 0 4px;
  margin-right: 2px;
  height: 18px;
  line-height: 18px;
  border: 1px solid #999999;
  border-radius: 3px;
  font-size: 13px;
  color: #999999;
}
.key-container .key-label {
  display: inline-block;
  margin-right: 10px;
  line-height: 20px;
  font-size: 13px;
  color: #999999;
}

/* .upload-image-button {
  width: auto !important;
  margin: 0 auto !important;
} */

/* table row height */
.p-datatable .p-datatable-tbody > tr {
  height: 130px;
}
/* table row height */
.contacts-page .p-datatable .p-datatable-tbody > tr {
  height: 60px;
}
/* table row height */
.reporting-page .p-datatable .p-datatable-tbody > tr {
  height: 60px;
}
/* limit image height in image column (to avoid 'high' images) */
.p-datatable .p-datatable-tbody td img {
  max-height: 100px;
}

.segmentInputRow {
  margin-bottom: -2.2rem;
}
.sportNameField {
  width: 54%;
}
.sportDurationField {
  width: 36%;
}
.sportDialogMinusButton {
  padding-left: 4px !important;
}
.dialogGeneralDescription {
  font-weight: 800;
  /* padding-top:  */
}
.dialogDescription {
  font-size: 11px;
  padding-bottom: 5px;
}
.dialogDescriptionOptional {
  font-weight: 200;
  color: #999;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b5b5b5;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b5b5b5;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b5b5b5;
}
::-webkit-scrollbar {
  /* width: 0;
  background: transparent; */
}
.p-datepicker {
  z-index: 99999 !important;
}
.compCalendarTo .p-datepicker {
  top: -346px !important;
  left: -180px !important;
}

.noPadding {
  padding: 0 !important;
}
/* advert page Advert page */
.advertPageHeaderAdDetails {
  margin-left: 30px;
}

.advertPageVersionBlock {
  min-height: 180px;
}

.advertPageVersionBlockDED {
  height: 180px;
}

.adImageBlock {
  margin: 14px auto;
  text-align: center;
  max-width: 80%;
}
.adImageContainer {
  margin: 14px auto;
  height: 80px;
  text-align: center;
  max-width: 80%;
}
.adImageContainer img {
  width: auto;
  max-width: 98%;
}
.adImageBlock img {
  height: 80px;
  width: auto;
  max-width: 98%;
}
.adImageBlockSV {
  margin: 14px auto;
  text-align: center;
  max-width: 80%;
}
.adImageBlockSV img {
  height: 250px;
  width: auto;
  max-width: 98%;
}
.advertPageMediaBlock {
  /* display: flex; */
}

.advertPageMediaInfo {
}
.advertPageUploadBlock {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

/* .ad5ZoneLayoutContaner {
width: 880px;
} */

.advert-page-general .adZoneViewPart {
  background-color: #dae1ee;
  /* width: 155px; */
  height: 20px;
  margin-right: 3px;
  float: left;
}

.advert-page-general .adZoneActive {
  /* background-color: #fff; */
  /* border: 1px solid grey; */
  margin-right: 3px;
  /* border: 2px  solid white 6px  solid white 2px  solid white 2px solid white; */
  /* cursor: pointer; */
}

.advert-page-general .adZoneActiveNoFile {
  background-color: rgb(178, 178, 181);

  /* margin-right: 3px; */
}

div.fill {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
div.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

/* .adZoneViewThumb {
max-height: 20;
margin: '0 auto';
max-width: 170px;
border: '1px solid lightgrey';
} */

/* @media only screen and (max-width: 4000px) {


} */

/* @media only screen and (max-width: 1920px) {
.advert-page .ad5ZoneLayoutContaner {
  width: 1200px;
}
.advert-page .adZoneViewPart  {
  max-width: 230px;
}
}

@media only screen and (max-width: 1600px) {
.advert-page .ad5ZoneLayoutContaner {
  width: 980px;
}
.advert-page .adZoneViewPart  {
  max-width: 190px;
}
}

@media only screen and (max-width: 1536px) {
.advert-page .ad5ZoneLayoutContaner {
  width: 900px;
}
.advert-page .adZoneViewPart  {
  max-width: 175px;
}
}

@media only screen and (max-width: 1440px) {
.advert-page .ad5ZoneLayoutContaner {
  width: 860px;
}
.advert-page .adZoneViewPart  {
  max-width: 19%;
}

}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1200px) {

}


@media only screen and (max-width: 800px) {

} */

@media only screen and (max-width: 1680px) {
  /* hide dropdown/sort icons and table header 'Results' */
  .reporting-page .p-dropdown-trigger,
  .reporting-page .p-sortable-column-icon,
  .reporting-page .reporting-page-header {
    display: none;
  }
}
@media only screen and (min-width: 1080px) {
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 270px;
  }
  .advert-page-general .adZoneViewPart {
    width: 18.5%;
  }
  .advert-page-general .adZoneViewPart.splitZone {
    width: 9.25%;
  }
}

@media only screen and (min-width: 1200px) {
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 402px;
  }
  .advert-page-general .adZoneViewPart {
    width: 18.5%;
  }
  .advert-page-general .adZoneViewPart.splitZone {
    width: 9.25%;
  }
}
@media only screen and (min-width: 1280px) {
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 484px;
  }
  .advert-page-general .adZoneViewPart {
    width: 18.5%;
  }
  .advert-page-general .adZoneViewPart.splitZone {
    width: 9.25%;
  }
}

@media only screen and (min-width: 1366px) {
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 556px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19%;
  }
  .advert-page-general .adZoneViewPart.splitZone {
    width: 9.5%;
  }
}
@media only screen and (min-width: 1440px) {
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 610px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
  .advert-page-general .adZoneViewPart.splitZone {
    width: 9.75%;
  }
}

@media only screen and (min-width: 1536px) {
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 710px;
  }
  .advert-page-general .adZoneViewPart {
    /* width: 182px; */
    width: 19.5%;
  }
  .advert-page-general .adZoneViewPart.splitZone {
    width: 9.75%;
  }
}

@media only screen and (min-width: 1600px) {
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 790px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
  .advert-page-general .adZoneViewPart.splitZone {
    width: 9.75%;
  }
}

@media only screen and (min-width: 1680px) {
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 870px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
  .advert-page-general .adZoneViewPart.splitZone {
    width: 9.75%;
  }
}

@media only screen and (min-width: 1730px) {
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 936px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}

@media only screen and (min-width: 1780px) {
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 956px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}

@media only screen and (min-width: 1850px) {
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 1050px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}

@media only screen and (min-width: 1920px) {
  #schedule-adlist {
    width: 480px;
  }
  .zone-modal .schedule-container {
    left: 510px;
  }
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 1100px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}

@media only screen and (min-width: 2000px) {
  #schedule-adlist {
    width: 480px;
  }
  .zone-modal .schedule-container {
    left: 510px;
  }
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 1200px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}

@media only screen and (min-width: 2080px) {
  #schedule-adlist {
    width: 480px;
  }
  .zone-modal .schedule-container {
    left: 510px;
  }
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 1275px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}
@media only screen and (min-width: 2160px) {
  #schedule-adlist {
    width: 480px;
  }
  .zone-modal .schedule-container {
    left: 510px;
  }
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 1320px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}

@media only screen and (min-width: 2250px) {
  #schedule-adlist {
    width: 500px;
  }
  .zone-modal .schedule-container {
    left: 530px;
  }
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 1440px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}

@media only screen and (min-width: 2380px) {
  #schedule-adlist {
    width: 500px;
  }
  .zone-modal .schedule-container {
    left: 530px;
  }
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 1570px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}

@media only screen and (min-width: 2490px) {
  #schedule-adlist {
    width: 500px;
  }
  .zone-modal .schedule-container {
    left: 530px;
  }
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 1680px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}

@media only screen and (min-width: 2580px) {
  #schedule-adlist {
    width: 520px;
  }
  .zone-modal .schedule-container {
    left: 550px;
  }
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 1770px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}

@media only screen and (min-width: 2680px) {
  #schedule-adlist {
    width: 520px;
  }
  .zone-modal .schedule-container {
    left: 560px;
  }
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 1870px;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}

@media only screen and (min-width: 2760px) {
  #schedule-adlist {
    width: 550px;
  }
  .zone-modal .schedule-container {
    left: 620px;
  }
  .advert-page-general .ad5ZoneLayoutContaner {
    width: 68vw;
  }
  .advert-page-general .adZoneViewPart {
    width: 19.5%;
  }
}

/* playout and original file names (download link) */
.advertPageDownloadLink {
  cursor: pointer;
  text-decoration: none;
  color: #1976d2;
}
.single-advert-page .p-progressbar-label {
  line-height: 2.7rem;
}
/* FILE UPLOAD customization */
.single-advert-page .p-fileupload.p-fileupload-advanced.p-component,
.media-page .p-fileupload.p-fileupload-advanced.p-component {
  margin-top: 10px;
}
.single-advert-page .p-fileupload-content {
  padding: 0;
}
/* .p-fileupload-row .p-button-icon-only {
  display: none;
} */
/* .p-fileupload-row div:last-of-type {
  display: none;
} */
/* truncate file name when it's longer than div */
.p-fileupload-row div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.advertStatus {
  margin: 14px 0;
  font-size: 20px;
  text-align: center;
  animation: blinker 3s linear infinite;
}
.transcodeFileName {
  animation: blinker 3s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* Outputs */
/* .outputListItem {
  line-height: 1.1;
} */
ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
.no-bullets li {
  line-height: 2;
}

/* Venue page - country-flag dropdown list */
.country-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.country-item img.flag {
  width: 18px;
  margin-right: 0.5rem;
}
.p-progressbar-determinate .p-progressbar-value-animate {
  transition: 0.2s ease-in-out !important;
  /* width 0.1s ease-in-out; */
}

.loading-bar {
  position: relative;
  margin: 0 auto;
  height: 40px;
  /* width: 100%; */
  border-radius: 0px;
}

div.myProgressBar {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;

  border-radius: 0px;
  background-image: linear-gradient(to bottom, rgb(56, 163, 56), rgb(16, 112, 16) 60%);
  box-shadow:
    inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

div.myProgressBar:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  /* border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; */
  overflow: hidden;
}

.loading-bar > div.myProgressBar:after,
.animate > div.myProgressBar > div.myProgressBar {
  animation: load 1.5s infinite;
}

@keyframes load {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

/* html::-webkit-scrollbar {
  display: none;
}
@-moz-document url-prefix() {
  html{
    scrollbar-width: none;
  }
}
body {
  margin: 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: scroll;

  background-color: #f6f8fa;
}
body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
*:focus {
  outline: 0;
} */

.loginPage {
  /* position: absolute; */
  /* margin-top: 30px;
  height: 180px;
  width: 100%; */
  /* top: 5px;
  left: 5px; */
}
/* .loginPageLeft {
  float: left;
  width: 30%;
  height: 100%;
}
.loginPageRight {
  float: right;
  width: 30%;
  height: 100%;
} */
.HubAlanyticsNReporting {
  /* font-weight: 600;

  font-size: 32px; */
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-size: 24px;
  letter-spacing: 2px;
  word-spacing: 2px;
  color: #000000;
  font-weight: normal;
  text-decoration: none solid rgb(68, 68, 68);
  font-style: normal;
  /* font-variant: small-caps; */
  font-weight: 400;
  text-transform: none;
}

.loginPageCenter {
  margin: 0 auto;
  width: 50%;
  /* width: "calc(100% - 620)"; */
  height: 100%;
  text-align: center;
}
.loginPageCenter {
  /* ALEX 18.01.2022
  instead of margin-top: 7% used padding-top: 4% to prevent scroll appearance on the screen */
  /* margin-top: 7%; */
  padding-top: 5%;
  margin-bottom: -30px;
}
.loginPageCenter3 {
  margin: 0 auto;
  width: 40%;
  position: relative;
  margin-top: 30px;
  /* width: "calc(100% - 620)"; */
  /* height: 100%; */
  text-align: center;
}

.loginPageOptions {
  position: absolute;
  right: 4px;
  bottom: 64px;
  /* width: 30px;
  height: 40px; */
}
.loginPageDescription {
  text-align: center;
  font-size: 16px;
  /* margin-top: -20px; */
}

.loginPageFooter a {
  color: inherit;
}
.loginPageLine {
  /* margin-top: -25px; */
  /* margin-top: -20px;
  border-top: 1px solid #8c8b8b; */
  margin: 8px auto;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
  width: 60%;
  /* border: 0;
  height: 0;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); */
}
.loginPageSportfiveLogo {
  width: 100px;
  /* opacity: 0.8; */
}
.loginPageFooter {
  height: 22px; /* added to fix semantic ui related issues */
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.loginPageFooterLine {
  /* position: absolute;
  bottom: 50px; */
  /* margin-top: -20px; */
  margin-top: -34px;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
/* remove "No account? Create account" from login form */
/* Very hacky workaround, solved with CSS trick */
/* TODO: remove it in app.js by removing or customizing
SectionFooterSecondaryContent subcomponent  */
span[class^='Section__sectionFooterSecondaryContent'] {
  visibility: hidden;
}
/* don't hide other hints that are shown inside
"sectionFooterSecondaryContent" on various other pages */
a[data-test='verify-contact-skip-link'],
a[data-test='forgot-password-resend-code-link'],
a[data-test='forgot-password-back-to-sign-in-link'],
a[data-test='require-new-password-back-to-sign-in-link'] {
  visibility: visible !important;
}
.footerText {
  margin-left: 40px;
  font-size: 14px;
  display: inline-block;
  letter-spacing: 0.7px;
  word-spacing: 1.6px;
  vertical-align: middle;
  color: #999;
}
/* remove login form inputs outline onClick */
form input:focus {
  outline: none;
}
.logoSupponor {
  height: 34px;
  margin-left: 40px;
  opacity: 0.9;
  /* display: inline-block; */
  vertical-align: middle;
  /* float: left; */
  /* margin-right: 80px; */
}

button[variant='oAuthSignInButton'] > span {
  /* background-color: black !important; */
}

/* Calendar page */

/* hide bullet points in list view in front of event names */
.fc .fc-list-event-dot {
  display: none;
}
.calendarEventLocation {
  font-size: 0.8rem;
  color: rgb(109, 107, 107);
}
.calendarEventLocation .pi {
  font-size: 0.6rem;
}
.calendarEventDetails {
  /* margin-top: -3px; */
  /* line-height: 0.5; */
}
/* ensure list label in the calendar header
stays blue when list view is enabled */
.fc-listMonth-button {
  background-color: white !important;
  border-color: #ced4da !important;
  color: #495057 !important;
}
.fc-listMonth-button:hover {
  background-color: #ced4da !important ;
}
.fc-button-active {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
  color: white !important;
}
.fc-button-active:hover {
  background-color: #2196f3 !important ;
}
.fc-button-group button:focus {
  box-shadow: none !important;
}

.calendarEventTitle {
  font-weight: 600;
  white-space: normal;
  letter-spacing: -0.01rem;
}
.calendarEventLink {
  color: black;
  cursor: pointer;
}
.calendarEventTooltipIconGrey {
  color: #6c757d;
  font-size: 0.7rem;
  cursor: pointer;
}
.calendarEventTooltipIconBlue {
  color: #2196f3;
  font-size: 0.7rem;
  cursor: pointer;
}
/* whole tooltip */
#eventTooltipId {
  padding: 0;
  width: 300px;
  /* max-width: 350px; */
  background-color: #fff !important;
  opacity: 1 !important;
  max-height: 400px;
}
/* tooltip body */
.eventTooltip {
  opacity: 1 !important;
}
/* divider text - non-bold */
.eventTooltip b {
  font-weight: 400;
}
.calendarEventTooltipHeader {
  text-align: center;
  /* background-color: #434557;
  color: rgb(127, 160, 221); */
  background-color: #125fab;
  color: white;
}
.calendarEventTooltipHeader span,
.calendarEventTooltipHeader i {
  cursor: pointer;
}
.calendarEventTooltipBody td {
  border: 0px;
}
.greyTableRow {
  background-color: #f3f3f3;
}
.calendarEventTooltipBody tr {
  padding: 25px !important;
}

.calendarEventTooltipBody {
  padding: 5px;
}

.tooltipTableRow {
  display: flex;
  justify-content: space-between;
  height: 25px;
  vertical-align: middle;
}
.tooltipTableRow:hover {
  background-color: #d7d7d7;
}
.tooltipTableCellLeft {
  /* width: 60px; */
  vertical-align: middle;
  line-height: 25px;
}
.tooltipTableCellRight {
  width: auto;
  vertical-align: middle;
  line-height: 25px;
}
.calendarTeamName {
  width: 32px;
  display: inline-block;
  text-align: center;
}
/* datepicker 'jump to month' button */
div.react-datepicker {
  margin-top: 40px;
  position: absolute;
  z-index: 9999;
}
.fc-gotoButton-button {
  padding: 0 !important;
}
.fc-gotoButton-button .pi-calendar {
  width: 34px;
  height: 32px;
  line-height: 32px;
  background-color: #2182cd;
}

/* Event page upper part */
.oneRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.oneRowFlexStart {
  display: flex;
  justify-content: flex-start;
}
.oneRowLeft,
.oneRowRight {
  display: flex;
  align-items: center;
  /* flex-wrap:wrap-reverse; */
}
.exportButton {
  min-width: 132px;
}

#settingsDialog .p-dialog-content {
  height: 350px;
}
.settingsPage {
  min-height: 67vh;
}

/* 3D VIEWER RELATED */
/* .outp_sched .icon-upload {
background-position: -143px -23px;
} */
.pw_3d {
  max-width: 100%;
  overflow-x: hidden; /* these 2 lines disable horizontal scroll */
  background: #333;
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}
.preview-header {
  float: left;
  height: 2vh;
  min-height: 20px;
  color: #888;
  /* //#5d6d1e; */
  font:
    14px/20px Arial,
    sans-serif;
}
.preview-page {
  width: 100%;
  height: 94vh; /* 94% of browser screen height */
  min-height: 870px;
}
.preview-block {
  width: 100%;
  height: 640px;
  border-radius: 15px;
}
/* Macbook retina displays */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .preview-block {
    max-height: 58vh;
  }
}
.preview-block canvas {
  border-radius: 7px;
}
.controls-block {
  width: 100%;
  height: calc(100% - 700px);
  /*padding-top: 10px;*/
  margin-top: 100px;
}
.left-controls-block {
  float: left;
  width: 82vw;
  height: 100%;
  /*width: 62%;*/
  border-radius: 7px;
  border-color: transparent;
  -webkit-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.6),
    0px 1px 0px rgba(105, 105, 105, 0.6);
  -moz-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.6),
    0px 1px 0px rgba(105, 105, 105, 0.6);
  box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.6),
    0px 1px 0px rgba(105, 105, 105, 0.6);
}
/* ensure control blocks are in line on smaller screens */
@media only screen and (max-width: 1100px) {
  .pw_3d .left-controls-block {
    width: 68vw;
  }
}
.right-controls-block {
  border: 20px;
  float: right;
  padding: 7px 6px 3px 5px;
  border-radius: 7px;
  border-color: transparent;
  background: #333;
  -webkit-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  -moz-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
}
.pw.pw_3d {
  /* 'normal' .pw has padding-top: 40px, need to override it to free some space for custom header */
  padding-top: 0px;
}

.pw_3d ::-webkit-scrollbar {
  /*width: 12px;
  height: 12px;*/
}
/* Track */
.pw_3d ::-webkit-scrollbar-track {
}
/* offset for thumb from the edge */
.pw_3d ::-webkit-scrollbar-button {
  width: 2px;
  height: 3px;
}
/* bottom right corner */
/* .pw_3d ::-webkit-scrollbar-corner { */
/*background: rgba(0,0,0,0);*/ /* make it transparent */
/* } */

.pw_3d ::-webkit-scrollbar-thumb:horizontal {
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  background: -webkit-linear-gradient(bottom, #3a3a3a 0%, #333 50%, #3a3a3a 100%);
  background: -moz-linear-gradient(bottom, #333 0%, #444 50%, #333 100%);
  background: -o-linear-gradient(right, #333 0%, #444 50%, #3a3a3a 100%);
  background: -ms-linear-gradient(right, #333 0%, #444 50%, #333 100%);
  background: linear-gradient(right, #333 0%, #444 50%, #333 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333', endColorstr='#444',GradientType=0);
  border-radius: 10px;
}
.pw_3d ::-webkit-scrollbar-thumb:vertical {
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  background: -webkit-linear-gradient(right, #3a3a3a 0%, #333 50%, #3a3a3a 100%);
  background: -moz-linear-gradient(right, #333 0%, #444 50%, #333 100%);
  background: -o-linear-gradient(right, #333 0%, #444 50%, #333 100%);
  background: -ms-linear-gradient(right, #333 0%, #444 50%, #333 100%);
  background: linear-gradient(right, #333 0%, #444 50%, #333 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333', endColorstr='#444',GradientType=0);
  border-radius: 10px;
}

table.intervals {
  /*border-collapse: separate !important;*/ /* this allows border-radius https://stackoverflow.com/questions/10666573/border-radius-of-table-is-not-working*/
  margin: 4px 1px 0px 3px;
  background: #333;
  color: #999;
  display: block;
  overflow-x: auto; /* horizontal scroll */
  overflow-y: auto; /* vertical scroll */
  white-space: nowrap;
  height: calc(
    100% - 6px
  ); /* 100% - margin-top - 2px (to be sure): to make bottom shadow visible */
}

table.intervals tbody,
table.intervals thead {
  min-height: 20px;
  /* disable text selection */
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
table.intervals thead {
  height: 20px;
  position: sticky;
  top: 0;
  background-color: #222;
  background: linear-gradient(#292929, #222222);
  border-radius: 5px;
  display: block; /* changes to 'display: table-header-group' in JS on horizontal scroll appearance */
}
/* even stripes color */
.intervals tr:nth-child(even) {
  background-color: #2f2f2f;
}
.intervals tbody td:last-child,
thead th:last-child {
  border-right: none;
}

/* .intervals tr td:nth-child(1) {
width: 200px;
}
.intervals th td:nth-child(1) {
width: 140px;
} */
/* interval name column */
.intervals tbody td:nth-child(2) {
  /* padding-left: 0px; */
}
/* number and time column */
/* .intervals tbody td:first-child {
padding-left: 0px;
padding-right: 0px;
} */
.interval-row {
  width: 100%;
}
table.intervals .segment-header {
  color: #aaa;
  font-weight: bold;
}
table.intervals tbody tr.interval-row:hover {
  background: linear-gradient(#444, #666);
  background-color: #555; /* for IE */
  color: black;
  cursor: pointer;
}
table.intervals td {
  width: 300px;
}
.tiltCheckbox input[type='checkbox'] {
  visibility: hidden;
}
.tiltCheckbox {
  width: 104px;
  height: 26px;
  background: #333;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  position: relative;
  -webkit-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  -moz-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
}

.tiltCheckbox:after {
  content: 'no tilt';
  font:
    11px/26px Arial,
    sans-serif;
  color: #000;
  position: absolute;
  right: 9px;
  z-index: 0;
  font-weight: bold;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.15);
}

.tiltCheckbox:before {
  content: 'tilt';
  font:
    11px/26px Arial,
    sans-serif;
  color: green;
  position: absolute;
  left: 18px;
  z-index: 0;
  font-weight: bold;
}
.backgroundCheckbox:after {
  font:
    11px/26px Arial,
    sans-serif;
  content: 'hide';
  right: 13px;
}
.backgroundCheckbox:before {
  font:
    11px/26px Arial,
    sans-serif;
  left: 10px;
  content: 'show';
}
.animationCheckbox:after {
  content: 'OFF';
  right: 14px;
}
.animationCheckbox:before {
  content: 'ON';
}

.tiltCheckbox label {
  display: block;
  width: 58px;
  height: 20px;

  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;

  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  /* background: #fcfff4; */
  background: rgba(33, 33, 33, 1);

  background: linear-gradient(top, #555 0%, #444 40%, #333 100%);
  background: -moz-linear-gradient(top, #555 0%, #444 40%, #333 100%);
  background: -o-linear-gradient(top, #555 0%, #444 40%, #333 100%);
  background: -ms-linear-gradient(top, #555 0%, #444 40%, #333 100%);
  background: -webkit-linear-gradient(top, #555 0%, #444 40%, #333 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#555', endColorstr='#333',GradientType=0);
}

.tiltCheckbox input[type='checkbox']:checked + label {
  left: 43px;
}
.resetCamView {
  width: 104px;
  height: 26px;
  padding-top: 3px;
  display: block;
  font-weight: bold;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.15);
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  cursor: pointer;
  font:
    12/26px Arial,
    sans-serif;
  text-align: center;
  color: #111;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  background: #fcfff4;
  background: linear-gradient(top, #555 0%, #444 40%, #333 100%);

  background: -moz-linear-gradient(top, #555 0%, #444 40%, #333 100%);
  background: -o-linear-gradient(top, #555 0%, #444 40%, #333 100%);
  background: -ms-linear-gradient(top, #555 0%, #444 40%, #333 100%);

  background: -webkit-linear-gradient(top, #555 0%, #444 40%, #333 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#555', endColorstr='#333',GradientType=0);
}
.resetCamView:hover {
  color: #002a00;
}
#outp-share-schedule {
  text-decoration: none;
  margin-bottom: 7px;
}
/* vertical offset between .right-controls-block elements */
.tiltCheckbox,
.resetCamView {
  margin: 0px auto 12px auto;
}
#cameraFOV {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  width: 80px;
  padding: 0; /* override 4px default input padding */
  margin: 0;
  margin-right: -4px;
}
#infoFOV,
.min-max-zoom {
  font:
    11px/11px Arial,
    sans-serif;
  color: #000;
  font-weight: bold;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.15);
}
input#cameraFOV[type='range'] {
  -webkit-appearance: none;
  background-color: #333;
}
input#cameraFOV[type='range']:focus {
  outline: none;
}
input#cameraFOV[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  background: #333;
  -webkit-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  -moz-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
}
input#cameraFOV[type='range']::-webkit-slider-thumb {
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  border: 0px solid rgba(0, 0, 0, 0);
  height: 18px;
  width: 21px;
  border-radius: 3px;
  background: #555555;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4.8px;
}
input#cameraFOV[type='range']:focus::-webkit-slider-runnable-track {
  background: #2f2f2f;
}
input#cameraFOV[type='range']::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  background: #333;
  -webkit-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  -moz-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
}
input#cameraFOV[type='range']::-moz-range-thumb {
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  border: 0px solid rgba(0, 0, 0, 0);
  height: 18px;
  width: 21px;
  border-radius: 3px;
  background: #555555;
  cursor: pointer;
}
input#cameraFOV[type='range']::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input#cameraFOV[type='range']::-ms-fill-lower {
  background: #151515;
  border: 0px solid #010101;
  border-radius: 5px;
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
}
input#cameraFOV[type='range']::-ms-fill-upper {
  background: #333;
  -webkit-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  -moz-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  /*  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;*/
}
input#cameraFOV[type='range']::-ms-thumb {
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  border: 0px solid rgba(0, 0, 0, 0);
  height: 18px;
  width: 21px;
  border-radius: 3px;
  background: #555555;
  cursor: pointer;
  height: 8.4px;
}
input#cameraFOV[type='range']:focus::-ms-fill-lower {
  background: #333;
  -webkit-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  -moz-box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
  box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
}
input#cameraFOV[type='range']:focus::-ms-fill-upper {
  background: #2f2f2f;
}
span#camera-position,
span#calculation-time {
  font-size: 10px;
  color: #9e8362;
  position: absolute;
  right: 20px;
  top: 1px;
}
span#calculation-time {
  right: 190px;
}
/* 3D Preview intervals table scrollbar */
.intervals::-webkit-scrollbar {
  width: 0.75rem;
}
.intervals::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(31, 12, 78, 0.4);
}
.intervals::-webkit-scrollbar-thumb {
  background-color: rgba(22, 30, 38, 0.5);
  outline: 1px solid rgb(22, 30, 38, 0.8);
}
.loginButtonLogo {
  position: absolute;
  left: 32px;
  bottom: 9px;
  width: 33px;
}
.loginButtonText {
  /* color: white;  */
  letter-spacing: 0.1rem;
  font-size: 16px;
  color: '#ecfff9';
  /* text-shadow: #e0e0e0 1px 0px 0; */

  margin-left: 52px;
  margin-top: 0px;
}
.idpDescription-customizable {
  display: none !important;
}
.breadcrumbSeparator {
  margin-left: 8px;
  margin-right: 8px;
}
.adNameBlock {
  border-right: 1px inset #ccc;
  padding-right: 30px;
}

.adPageHeader {
  vertical-align: middle;
  display: flex;
  /* height: 100px; */
  line-height: 5rem;
  margin-bottom: 10px;
}
.adPageHeader .p-fieldset .p-fieldset-legend {
  padding: 0px;
}
.verticalAlignMiddle {
  vertical-align: middle !important;
}
.greyedOut {
  border: 1px solid #c7c7c7 !important;
  color: #c7c7c7 !important;
}
.greyedOut.currentFeed {
  background: #a3a3a3 !important;
  color: #eee !important;
}
/* playlist manager ads list color */
#schedule-adlist .p-datatable .p-datatable-tbody > tr {
  color: #000;
}
/* DED playlist ad cell */
.regular-ad {
  padding: 0px 2px;
  border: 1px solid #dadada;
  border-radius: 3px;
}
/* SPZ ad cell */
.spz-ad {
  width: 49%;
  float: left;
}
/* vertical border between SPZ ads in the subzones */
.spz-ad-border {
  border-right: 2px solid lightgrey;
}
.blank-ad {
  color: #a1a6b7;
  letter-spacing: 0.6px;
}
.grey999 {
  color: #999;
}
/* playlist manager icons */
.redColor {
  color: #f56565;
}
.greenColor {
  color: #449b47;
}
/* playlist manager tools menu */
#p_zones .p-menuitem-link {
  padding-right: 4px;
}
/* scrollmenu feed buttons in playlist manager header */
.scrollmenu .p-button {
  padding: 0.5rem 0.5rem;
}
.scrollmenu .p-button .p-button-icon-left {
  margin-right: 0.4rem;
}
/* manage templates */
.smallDialog .p-dialog-content {
  padding: 0 0.5rem 2rem 0.5rem;
}
.p-datatable.smallDataTable .p-datatable-tbody > tr {
  height: 50px;
}
/* tools PlaylistPage */
.horizontal_tools {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.third_block {
  display: flex;
  flex-direction: column;
  width: 60rem;
}

.tabmenu_container {
  margin-top: 2.9rem;
}

.radio_button_container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-bottom: 0.5rem;
}

.radio_button {
  display: flex;
  gap: 0.7rem;
}

.header_container {
  height: 142px !important;
  display: flex;
  flex-direction: column !important;
}
.upper_header_container {
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  height: 94px;
  margin-bottom: 48px;
}

.second_block {
  width: 230px;
}

.fourth_block {
  display: flex;
  gap: 0.5rem;
  /* flex-wrap: wrap; */
}

.outputs_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b9c9f333;
}

.outputs_switch {
  display: flex;
  gap: 6rem;
}

/* Switch Version */
.switch_container {
  margin-left: 7rem;
  margin-right: -10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.output_channel {
  margin-top: 0.5rem;
}

/* Footer */
.close-footer-button {
  margin-right: 2rem;
}
.selected {
  margin-right: 70rem;
}

/* Old Version Header */
.zone-modal .zone-modal-body-old {
  position: absolute;
  top: 98px;
  left: 0;
  right: 0;
  bottom: 40px;
  overflow: hidden;
  background-color: #fbf9f5;
}
.zone-modal.with-paste-mode .zone-modal-body-old {
  bottom: 72px;
}
.zone-modal.with-help-bar .zone-modal-body-old {
  top: 127px;
}

.zone-modal .zone-modal-body-old-no-footer {
  position: absolute;
  top: 98px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #fbf9f5;
}
.zone-modal.with-paste-mode .zone-modal-body-old-no-footer {
  bottom: 72px;
}
.zone-modal.with-help-bar .zone-modal-body-old-no-footer {
  top: 127px;
}

.schedule-header-feeds-old {
  width: calc(100% - 845px);
  /* height: 48px; */
  /* background-color: #f7f7f7; */
}

.scrollmenu-old {
  /* background-color: #777; */
  overflow: auto;
  white-space: nowrap;
  display: flex;
  gap: 0.3rem;
}
.scrollmenu-old::-webkit-scrollbar {
  height: 6px; /* height of horizontal scrollbar */
  width: 4px; /* width of vertical scrollbar */
  border: 1px solid lightblue;
}
.scrollmenu-old::-webkit-scrollbar:vertical {
  display: none;
}
.scrollmenu-old::-webkit-scrollbar-thumb:horizontal {
  background: rgb(151, 199, 242);
  background: radial-gradient(circle, rgba(151, 199, 242, 1) 35%, rgba(182, 217, 249, 1) 100%);
}
div.scrollmenu-old span {
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.scrollmenu-old .p-button {
  padding: 0.5rem 0.5rem;
}
.scrollmenu-old .p-button .p-button-icon-left {
  margin-right: 0.4rem;
}

.save-close-container {
  display: flex;
  gap: 1rem;
}

.switch-container-old {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: -0.5rem;
  height: 33px;
}

.fourth-block-old {
  display: flex;
  gap: 0.5rem;
}

/* Responsiveness */
@media (max-width: 1500px) {
  .switch_container {
    margin-right: -2rem;
  }
}

@media (max-width: 1435px) {
  .modal-header-old {
    margin-right: -5rem;
  }
  .switch_container {
    margin-left: 1rem;
  }
}

@media (max-width: 1335px) {
  .schedule-header-feeds {
    width: 450px;
  }
}

@media (max-width: 1282px) {
  .second_block {
    width: 185px;
  }
  .fourth_block {
    flex-direction: column;
  }
}
.icon-radio {
  content: url('assets/icon-radio.png');
  width: 1rem;
  margin-top: -3px;
}
.icon-radio-blue {
  content: url('assets/icon-radio-blue.png');
  width: 1rem;
  margin-top: -3px;
}
.icon-tv {
  content: url('assets/icon-tv.png');
  width: 1rem;
  margin-top: -4px;
}
.icon-tv-blue {
  content: url('assets/icon-tv-blue.png');
  width: 1rem;
  margin-top: -4px;
}

.ded-video-asset {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.react-player {
  width: 100% !important;
  height: 5rem !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  position: relative !important;

  :global(video) {
    position: absolute;
    max-width: none !important;
    height: 100% !important;
    width: auto !important;
    display: block;
  }
}

.adVideoBlock {
  margin: 14px auto;
  text-align: center;
  max-width: 80%;
}

/* Validator */
.status-container {
  display: flex;
  gap: 20px;
  margin-left: 20px;
}
.status-item {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
}
.p-datatable.narrow-rows .p-datatable-tbody > tr {
  height: 30px;
  padding: 0.5px;
}

/* PlaylistPage tooltip */
.asset-tooltip.p-tooltip .p-tooltip-text {
  background-color: #292929;
  width: 715px;
}

.playlistVideoPreview {
  /* margin: 14px auto; */
  /* margin-left: 2px;
  margin-left: 2px; */
  /* text-align: center; */
  /* max-width: 95%; */
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playlistImagePreview {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playlistIconsFilters {
  display: flex;
  gap: 6px;
}
/* Page Title Style */
.page-title {
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  color: #525252;
  font-weight: 600;
}

/* Header DataTable style */
.table-header-buttons {
  display: flex;
  align-items: center;
}

/* FullCalendar */
.buttonContainer {
  display: inline-block;
}
.listHeadersContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fc-list-day-cushion.fc-cell-shaded.listHeadersContainer::after {
  content: none !important;
}

.fc-list-event-dot {
  content: none !important;
  display: none !important;
}
